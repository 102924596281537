import React, { Component } from 'react';
import { Button, Col, Input, Row, Modal, Form, ModalBody } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard, SelectHotel } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomTable } from '../Base/Common/CustomTable';
import { getStatus, getChannels, getRightVoucherId } from '../Base/Common/ReferenceDataFunctions';
import AddVoucher from './AddVoucher';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization from '../Base/Authorization';
import { handleNotification } from '../Base/Common/Notification';
import moment from 'moment';

export class OperationManagement extends Component {
    static displayName = OperationManagement.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.getVouchers = this.getVouchers.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.voucherIdForm = React.createRef();
        this.state = {
            block: true,
            blockCfg: true,
            voucherList: [],
            voucherConfigList: [],
            currentPage: 0,
            PageSize: 10,
            filterVoucherId: ''
        }
    }


    componentDidMount() {
        this.getVouchers();
        this.getConfig();

    }

    getConfig() {
        this.setState({ blockCfg: true });
       
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCfg: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let arr = [];
                    data.response.map((el) => { arr.push({ value: el.id, label: el.code }) })

                    this.setState({ voucherConfigList: arr });
                }
            }
            this.setState({ error: errorMessage, blockCfg: false });
        }, '/api/Voucher/V1/Config' );
    }

    getVouchers = (e, totalItemsToExport, cb) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        let param = '?PageSize=' + (totalItemsToExport || this.state.PageSize) + '&PageIndex=' + this.state.currentPage;
        if (this.state.filterVoucherId) {
            param += '&voucherId=' + this.state.filterVoucherId;
        }
        if (this.state.filterStatus ) {
            param += '&status=' + this.state.filterStatus;
        }
        if (this.state.filterChannel) {
            param += '&channel=' + this.state.filterChannel;
        }
        if (this.state.filterVoucherType !== undefined) {
            param += '&type=' + this.state.filterVoucherType;
        }
        if (this.state.selectedHotel) {
            param += '&hotelId=' + this.state.selectedHotel;
        }
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ voucherList: data, block: false }, () => {
                        if (cb) {
                            cb();
                            this.getVouchers()
                        }
                    });
                } else {
                    this.setState({ voucherList: [], block: false });
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Voucher/Voucher/v1/listvouchers' + param);
    }

    updateFilter(evt) {
        if (evt.target) {
            const name = evt.target.name;
            const value = evt.target.value;
            this.setState({ [name]: value });
        }
    }

    updateFilterCombo(val, evt) {
        if (evt) {
            let name = evt.name;
            let value = val ? val.value : val;

            this.setState({ [name]: value });
        }
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        //const currentIndex = (page - 1) * sizePerPage;

        this.setState({
            currentPage: (page-1),
            voucherList: this.state.voucherList,
            PageSize: sizePerPage
        }, () => { this.getVouchers() });
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    saveVoucher() { }

    handleCustomExportClick = (cb) => {
        this.setState({ block: true }, _ => this.getVouchers(null, 1000, cb));
    }

    render() {

        const columns = [
            {
                dataField: 'voucherId',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardId" }),
                sort: true,
                formatter: (cell, row) => getRightVoucherId(cell, row.voucherIdFirst8)
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.SoldAt" }),
                sort: true
            },
            {
                dataField: 'saleOrigin',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Channel" }),
                sort: true
            },
            {
                dataField: 'ordinal',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Number" }),
                sort: true
            },
            {
                dataField: 'voucherTypeCode',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardType" }),
                sort: true
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "OperationManagement.IssueDate" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : null
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Status" }),
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        const status = getStatus().find(s => s.value === cell);
                        return status ? status.label : ''
                    }
                }
            },
            {
                dataField: 'validUntil',
                text: this.props.intl.formatMessage({ id: "OperationManagement.ValidUntil" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/VoucherOperationDetails/'+row.id)
            }
        };


        return (
            <StyledCard icon={'fas fa-gift'} title={'OperationManagement.AllCards'} help={<FormattedMessage id="OperationManagement.Help" />} id="operationManagement" error={this.state.error} block={this.state.block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalBody className="modal-xl p-0">
                        <AddVoucher selectedVoucher={{}} getVouchers={() => {this.toggleModal(); this.getVouchers()}} operations={true}/>
                    </ModalBody>
                </Modal>

                <Form onSubmit={this.getVouchers}>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <form ref={this.voucherIdForm}>
                                <FormattedMessage id="OperationManagement.CardId" >
                                    {
                                        placeholder => <Input
                                            type="text"
                                            placeholder={placeholder}
                                            id="filterVoucherId"
                                            name="filterVoucherId"
                                            value={this.state.filterVoucherId ? this.state.filterVoucherId : ''}
                                            onChange={this.updateFilter.bind(this)}
                                            minLength={8}
                                            maxLength={36}
                                        />
                                    }
                                </FormattedMessage>
                            </form>
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={this.state.voucherConfigList} isClearable value={this.state.voucherConfigList.find(el => el.value === this.state.filterVoucherType)} name="filterVoucherType" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.CardType" />} />
                        </Col>
                        <Col className="col-2">
                            <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'selectedHotel')} value={this.state.selectedHotel} />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={getStatus()} isClearable value={getStatus().find(el => el.value === this.state.filterStatus)} name="filterStatus" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.Status" />} />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={getChannels()} isClearable value={getChannels().find(el => el.value === this.state.filterChannel)} name="filterChannel" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.Channel" />} />
                        </Col>
                        <Col className="text-right">
                            <Authorization
                                perform="operationManagement:add"
                                yes={() => (
                                    <Button className="btn-host btn-sm mr-2" onClick={this.toggleModal} > <i className="fas fa-plus " /></Button>
                                )}
                                no={() => <div></div>}
                            />
                            <Button className="btn-host btn-sm" type="submit">
                                <i className="fas fa-search " />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CustomTable
                    data={this.state.voucherList.response ? this.state.voucherList.response : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={true}
                    customExportCsv={true}
                    totalSize={this.state.voucherList.count}
                    sizePerPage={this.state.voucherList.pageSize}
                    onTableChange={this.handleTableChange}
                    search={false}
                    showTotal={true}
                    remote={true}
                    selectRow={selectRow}
                    tooltipMessage={<FormattedMessage id="OperationManagement.ExportCsv" />}
                    handleOnClick={this.handleCustomExportClick.bind(this)}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(OperationManagement);
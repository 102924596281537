import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Badge } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard, SelectHotel } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { handleNotification } from "../Base/Common/Notification";
import Authorization from '../Base/Authorization';

class ReferenceData extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hoteList: [],
            currentPage: 0,
            PageSize: 10,
            selectedHotel: null,
            usersList: [],
            paymentTypeList: [],
            channelsList: [],
            ratesList: []
        }
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    getReferenceData = () => {
        if (this.state.selectedHotel != null) {
            this.getRateCodes();
            this.getChannels();
            this.getPaymentType();
            this.getUsers();
        } 
        
    }

    getUsers = () => {
        this.setState({ blockUsers: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUsers: false });

                return;
            }
            if (data) {
                this.setState({ usersList: data.response, blockUsers: false, error: errorMessage });
            }
        }, `/api/Voucher/v1/Config/pmsUsers/${this.state.selectedHotel}`);
    }

    getPaymentType = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ paymentTypeList: data.response, error: errorMessage, block: false });
            }
        }, `/api/Voucher/v1/Config/paymentMethods/${this.state.selectedHotel}`);
    }

    getChannels = () => {
        this.setState({ blockChannels: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockChannels: false });

                return;
            }
            if (data) {
                this.setState({ channelsList: data.response, error: errorMessage, blockChannels: false });
            }
        }, `/api/Voucher/v1/Config/onlineChannels/${this.state.selectedHotel}`);
    }

    getRateCodes() {
        this.setState({ blockRates: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRates: false });

                return;
            }
            if (data) {
                this.setState({ ratesList: data.response, error: errorMessage, blockRates: false });
            }
        }, `/api/Voucher/v1/Config/rateCodes/${this.state.selectedHotel}`);
    }

    refreshSalesData = () => {
        this.setState({ blockPayment: true, blockUsers : true});

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPayment: false, blockUsers : false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.DataRefreshed" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ error: errorMessage, blockPayment: false, blockUsers: false});
            }
        }, `/api/Voucher/v1/Config/pmsReferenceDataSales/${this.state.selectedHotel}`);
    }

    refreshRateChannelData = () =>{
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.DataRefreshed" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/v1/Config/pmsReferenceData/${this.state.selectedHotel}`);
    }


    render() {

        const rateColumns = [
            {
                dataField: 'rateCodeName',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Code" }),
                sort: true
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Description" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Status" }),
                sort: true,
                formatter: (cell, row) => cell ? <Badge color='primary'> <FormattedMessage id="ReferenceData.Active" /> </Badge> : <Badge color='secondary'><FormattedMessage id="ReferenceData.Inactive" /> </Badge> 
            }
        ]

        const channelColumns = [
            {
                dataField: 'channelCode',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Code" }),
                sort: true
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Description" }),
                sort: true
            },
            {
                dataField: 'isChannelManager',
                text: "isChannelManager",
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Status" }),
                sort: true,
                formatter: (cell, row) => cell ? <Badge color='primary'> <FormattedMessage id="ReferenceData.Active" /> </Badge> : <Badge color='secondary'><FormattedMessage id="ReferenceData.Inactive" /> </Badge> 
            }
        ]

        const paymentTypeColumns = [
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Code" }),
                sort: true
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Description" }),
                sort: true
            },
            {
                dataField: 'currencyCode',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Currency" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Status" }),
                sort: true,
                formatter: (cell, row) => cell ? <Badge color='primary'> <FormattedMessage id="ReferenceData.Active" /> </Badge> : <Badge color='secondary'><FormattedMessage id="ReferenceData.Inactive" /> </Badge> 
            }
        ]

        const usersColumns = [
            {
                dataField: 'userCode',
                text: this.props.intl.formatMessage({ id: "ReferenceData.Code" }),
                sort: true
            },
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "ReferenceData.FirstName" }),
                sort: true
            },
            {
                dataField: 'lastName',
                text: this.props.intl.formatMessage({ id: "ReferenceData.LastName" }),
                sort: true
            },
            {
                dataField: 'pmsId',
                text: this.props.intl.formatMessage({ id: "ReferenceData.pmsId" }),
                sort: true
            }
        ]


        return (
            <StyledCard icon={'fas fa-coins'} title={'ReferenceData.Title'} help={<FormattedMessage id="ReferenceData.Help" />} id="referenceData" error={this.state.error} block={this.state.block}>
                <Row>
                    <Col className="col-3">
                        <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'selectedHotel')} value={this.state.selectedHotel} />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mb-1" disabled={this.state.selectedHotel === undefined || this.state.selectedHotel === null}>
                            <i className="fas fa-search" onClick={this.getReferenceData} />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right mt-1">
                        <Authorization
                            perform="referenceData:syncRateChannel"
                            yes={() => (
                                <Button className="btn-sm btn-host mb-1" disabled={this.state.selectedHotel === undefined || this.state.selectedHotel === null}>
                                    <i className="fas fa-sync" onClick={this.refreshRateChannelData} />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col className="col-6">
                        <StyledCard icon={'fas fa-gift'} title={'ReferenceData.Rates'} className="h-100" block={this.state.blockRates}>                        
                            <CustomTable
                                data={this.state.ratesList ? this.state.ratesList : []}
                                columns={rateColumns}
                                shadow={false}
                                showTotal={true}
                                search={true}
                            />
                        </StyledCard>
                    </Col>
                    <Col className="col-6">
                        <StyledCard icon={'fas fa-plug'} title={'ReferenceData.Channels'} block={this.state.blockChannels}>
                            <CustomTable
                                data={this.state.channelsList ? this.state.channelsList : []}
                                columns={channelColumns}
                                shadow={false}
                                showTotal={true}
                                search={true}
                            />
                        </StyledCard>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="text-right">
                        <Authorization
                            perform="referenceData:syncUsersAndPayments"
                            yes={() => (
                                <Button className="btn-sm btn-host mb-1" disabled={this.state.selectedHotel === undefined || this.state.selectedHotel === null}>
                                    <i className="fas fa-sync" onClick={this.refreshSalesData} />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <StyledCard icon={'fas fa-money-bill-wave'} title={'ReferenceData.PaymentTypes'} block={this.state.blockPayment}>
                            <CustomTable
                                data={this.state.paymentTypeList ? this.state.paymentTypeList : []}
                                columns={paymentTypeColumns}
                                shadow={false}
                                showTotal={true}
                                search={true}
                            />
                        </StyledCard>
                    </Col>
                    <Col className="col-6">
                        <StyledCard icon={'fas fa-users'} title={'ReferenceData.Users'} block={this.state.blockUsers}>
                            <CustomTable
                                data={this.state.usersList ? this.state.usersList : []}
                                columns={usersColumns}
                                shadow={false}
                                showTotal={true}
                                search={true}
                            />
                        </StyledCard>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(ReferenceData);
import React, { Component } from 'react';
import { Modal, Button, Progress, Row, Col, CardBody, Card, Badge } from 'reactstrap';
import { getAPI } from '../Base/API';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';
import { SelectHotel, BlankCard, CommonHelper } from '../Base/Common/CommonUIComponents';
import { Pie, Line } from 'react-chartjs-2';
import moment from "moment";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import RedeemDashboard from './RedeemDashboard';
import OthersDashboard from './OthersDashboard'; 

export class SalesDashboard extends Component {
    static displayName = SalesDashboard.name;
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.redeemRef = React.createRef();
        this.othersRef = React.createRef();
        this.state = {
            block: true,
            view: 'sales',
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            hoteList: [],
            colorOnline: '#184C87',
            colorPms: '#00b4d8',
            colorCash: '#AAB71A',
            colorService: '#708843',
            colorDiscount: '#db231c'
        }
    }


    componentDidMount() {
        this.getSales();

    }

    getSales() {
        this.setState({ block: true });
        let param = '?fromDate=' + this.state.startDate.format('YYYY-MM-DD') + '&toDate=' + this.state.endDate.format('YYYY-MM-DD');

        this.redeemRef && this.redeemRef.current && this.redeemRef.current.getRedeems();
        this.othersRef && this.othersRef.current && this.othersRef.current.getOthers();
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let cardSummary = [];
                let channelsSummary = [];
                let totalSale = 0;
                let totalCard = 0;
                let totalCash = 0;
                let totalDiscount = 0;
                let totalOnline = 0;
                let totalCntCash = 0;
                let totalCntCard = 0;
                let totalPMS = 0;

                let dailySales = [];
                let dailySalesService = [];
                let dailySalesCash = [];

                let dailySalesOnline = [];
                let dailySalesPms = [];

                let dailyDiscount = [];
                
                data.response.map((el, k) => {

                    let aux = dailySales.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                    let aux1 = dailyDiscount.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                    
                    if (aux < 0) {
                        dailySales.push({ x: moment(el.createdAt).toDate(), y: el.salePrice });
                    } else {
                        dailySales[aux] = { x: dailySales[aux].x, y: el.salePrice + dailySales[aux].y };
                    }
                    if (aux1 < 0) {
                        dailyDiscount.push({ x: moment(el.createdAt).toDate(), y: (el.voucherValue - el.salePrice) });
                    } else {
                        dailyDiscount[aux1] = { x: dailyDiscount[aux1].x, y: (el.voucherValue - el.salePrice) + dailyDiscount[aux1].y };
                    }
                   

                    if (el.isPay === false) {
                        let auxService = dailySalesService.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());

                        if (auxService <0) {
                            dailySalesService.push({ x: moment(el.createdAt).toDate(), y: el.salePrice });
                        } else {
                            dailySalesService[auxService] = { x: dailySalesService[auxService].x, y: el.salePrice + dailySalesService[auxService].y };
                        }
                    } else {
                        let auxCash = dailySalesCash.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxCash < 0) {
                            dailySalesCash.push({ x: moment(el.createdAt).toDate(), y: el.salePrice });
                        } else {
                            dailySalesCash[auxCash] = { x: dailySalesCash[auxCash].x, y: el.salePrice + dailySalesCash[auxCash].y };
                        }
                    }

                    if (el.channel === 'online') {
                        let auxService = dailySalesOnline.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailySalesOnline.push({ x: moment(el.createdAt).toDate(), y: el.salePrice });
                        } else {
                            dailySalesOnline[auxService] = { x: dailySalesOnline[auxService].x, y: el.salePrice + dailySalesOnline[auxService].y };
                        }
                    } else {
                        let auxCash = dailySalesPms.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxCash < 0) {
                            dailySalesPms.push({ x: moment(el.createdAt).toDate(), y: el.salePrice });
                        } else {
                            dailySalesPms[auxCash] = { x: dailySalesPms[auxCash].x, y: el.salePrice + dailySalesPms[auxCash].y };
                        }
                    }

                    const cardsum = cardSummary.find(item => item.code === el.code);
                    if (cardsum === undefined) {
                        cardSummary.push(el);
                    }
                    else {
                        cardsum.salePrice += el.salePrice;
                        cardsum.count += el.count;
                        cardsum.voucherValue += el.voucherValue;
                    }

                    let channelsum = channelsSummary.find(item => item.code === el.code && item.channel === el.channel);
                    if (channelsum === undefined) {
                        channelsum = {
                            code: el.code,
                            channel: el.channel,
                            salePrice: el.salePrice,
                            count: el.count,
                            voucherValue: el.voucherValue
                        };
                        channelsSummary.push(channelsum);
                    }
                    else {
                        channelsum.salePrice += el.salePrice;
                        channelsum.count += el.count;
                        channelsum.voucherValue += el.voucherValue;
                    }

                    totalSale += el.salePrice;
                    totalDiscount += (el.voucherValue - el.salePrice);
                    if (el.isPay) {
                        totalCash += el.salePrice;
                        totalCntCash += el.count
                    } else {
                        totalCard += el.salePrice;
                        totalCntCard += el.count;
                    }
                    if (el.channel === 'online' ? (totalOnline += el.salePrice) : (totalPMS += el.salePrice));
                })

                
                const mapAsc = cardSummary.sort((a, b) => b.salePrice - a.salePrice);
                const mapChannels = channelsSummary.sort((a, b) => b.salePrice - a.salePrice);

                this.setState({
                    stats: mapAsc,
                    channelStats: mapChannels,
                    totalSale: totalSale,
                    totalDiscount: totalDiscount,
                    totalCard: totalCard,
                    totalCash: totalCash,
                    totalCntCard: totalCntCard,
                    totalCntCash: totalCntCash,
                    totalOnline: totalOnline,
                    totalPMS: totalPMS,
                    dailySalesService: dailySalesService.sort((a, b) => a.x - b.x),
                    dailySalesCash: dailySalesCash.sort((a, b) => a.x - b.x),
                    dailySales: dailySales.sort((a, b) => a.x - b.x),
                    dailySalesOnline: dailySalesOnline.sort((a, b) => a.x - b.x),
                    dailySalesPms: dailySalesPms.sort((a, b) => a.x - b.x),
                    dailyDiscount: dailyDiscount.sort((a, b) => a.x - b.x),

                    block: false
                });
            }
        }, '/api/Voucher/Dashboard/v1/SalesStatus' + param);
    }
    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }


    render() {
        let dataPieCard = {
            datasets: [{
                data: [this.state.totalCash, this.state.totalCard],
                backgroundColor: [
                    this.state.colorCash,
                    this.state.colorService
                ],
            }],
            labels: [
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Cash" })}`,
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Services" })}`,
            ],
           
        };

        let dataPieChannel = {
            datasets: [{
                data: [this.state.totalOnline, this.state.totalPMS],
                backgroundColor: [
                    this.state.colorOnline,
                    this.state.colorPms
                ],
            }],
            labels: [
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Online" })}`,
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Pms" })}`
            ],

        };

        let dataPieCnt = {
            datasets: [{
                data: [this.state.totalCntCash, this.state.totalCntCard],
                backgroundColor: [
                    this.state.colorCash,
                    this.state.colorService
                ],
            }],
            labels: [
                `# ${this.props.intl.formatMessage({ id: "SalesDashboard.Cash" })}`,
                `# ${this.props.intl.formatMessage({ id: "SalesDashboard.Services" })}`
            ],

        };
        
        let optionsPie = {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                   // fontColor: 'rgb(255, 99, 132)'
                }
            }
        }

        let objSalesType = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Total" }),
                        "data": this.state.dailySales ? this.state.dailySales : [],
                        "fill": true,
                        //"borderColor": "#f7f7f7",
                        "backgroundColor": "#f7f7f7",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Cash" }),
                        "data": this.state.dailySalesCash ? this.state.dailySalesCash : [],
                        "fill": false,
                        "borderColor": this.state.colorCash,
                        "backgroundColor": this.state.colorCash,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Services" }),
                        "data": this.state.dailySalesService ? this.state.dailySalesService : [],
                        "fill": false,
                        "borderColor": this.state.colorService,
                        "backgroundColor": this.state.colorService,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Discount" }),
                        "data": this.state.dailyDiscount ? this.state.dailyDiscount : [],
                        "fill": false,
                        "borderColor": this.state.colorDiscount,
                        "backgroundColor": this.state.colorDiscount,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    }


                ]
        };

        let objSalesChannel = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Total" }),
                        "data": this.state.dailySales ? this.state.dailySales : [],
                        "fill": true,
                        //"borderColor": "#f7f7f7",
                        "backgroundColor": "#f7f7f7",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Online" }),
                        "data": this.state.dailySalesOnline ? this.state.dailySalesOnline : [],
                        "fill": false,
                        "borderColor": this.state.colorOnline,
                        "backgroundColor": this.state.colorOnline,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Pms" }),
                        "data": this.state.dailySalesPms ? this.state.dailySalesPms : [],
                        "fill": false,
                        "borderColor": this.state.colorPms,
                        "backgroundColor": this.state.colorPms,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Discount" }),
                        "data": this.state.dailyDiscount ? this.state.dailyDiscount : [],
                        "fill": false,
                        "borderColor": this.state.colorDiscount,
                        "backgroundColor": this.state.colorDiscount,
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    }

                ]
        };

        let optionsAmount = {
            legend: {
                position: "left"
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            quarter: 'MMM D'
                        }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {

                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }

        return (
            
            <div>
                <Row className="mb-2">
                    <Col className="col-2">
                        <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'selectedHotel')} value={this.state.selectedHotel} />
                    </Col>
                    <Col className="col-2">
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate) : null}
                            startDateId="sellStartid"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={this.state.endDate ? moment(this.state.endDate) : null}
                            endDateId="sellEndid"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            required
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="col-1">
                        <CardBody style={{ cursor: 'pointer' }} onClick={() => this.setState({ view: 'sales' })} className={"w-100 py-1 font-weight-bolder text-center " + (this.state.view === "sales" ? "border-bottom-host-bold" : "")}> <FormattedMessage id="SalesDashboard.Sales" /></CardBody>
                    </Col>
                    <Col className="col-1">
                        <CardBody style={{ cursor: 'pointer' }} onClick={() => this.setState({ view: 'redeem' })} className={"w-100 py-1 font-weight-bolder text-center " + (this.state.view === "redeem" ? "border-bottom-host-bold" : "")}> <FormattedMessage id="SalesDashboard.Redeems" /></CardBody> 
                    </Col>
                    <Col className="col-1">
                        <CardBody style={{ cursor: 'pointer' }} onClick={() => this.setState({ view: 'others' })} className={"w-100 py-1 font-weight-bolder text-center " + (this.state.view === "others" ? "border-bottom-host-bold" : "")}> <FormattedMessage id="SalesDashboard.Others" /></CardBody>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={this.getSales.bind(this)}>
                            <i className="fas fa-search " />
                        </Button>

                        <CommonHelper help={<FormattedMessage id="Home.Help" />} id='dashboard' />
                    </Col>
                </Row>
                {this.state.view === 'sales' ?
                <Row>
                    <Col className="col-4" >
                            <BlankCard icon={'fas fa-money-bill-alt'} title={'Sales '} error={this.state.error} block={this.state.block}>
                                <div style={{ paddingBottom: '5rem' }}> 
                                    <h4 className="mb-4">
                                        <FormattedMessage id="SalesDashboard.TopCardSales" />
                                    </h4>
                                    {
                                        this.state.stats && this.state.stats.map((s, k) => 
                                            <div key={k}>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Row>
                                                            <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                                <b>{s.count} x {s.code}</b>
                                                            </Col>
                                                            <Col className="col-3 text-right">
                                                                <FormattedNumber value={s.salePrice} style="currency" currency={'EUR'}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Progress color={s.isPay ? 'cash' : 'service'} value={(s.salePrice / this.state.totalSale) * 100}  ></Progress>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr className="my-1" />
                                            </div>
                                        )
                                    }
                                    <h4 className="mb-4">
                                        <FormattedMessage id="SalesDashboard.TopCardSalesByChannel" />
                                    </h4>
                                    {
                                        this.state.channelStats && this.state.channelStats.map((s, k) => 
                                            <div key={k}>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Row>
                                                            <Col style={{ color: s.channel === 'online' ? this.state.colorOnline : this.state.colorPms }}>
                                                                <b>{s.count} x {s.code}</b>
                                                            </Col>
                                                            <Col className="col-3 text-right">
                                                                <FormattedNumber value={s.salePrice} style="currency" currency={'EUR'}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Progress color={s.channel === 'online' ? 'online' : 'pms'} value={(s.salePrice / this.state.totalSale) * 100}  ></Progress>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr className="my-1" />
                                            </div>
                                        )
                                    }
                                    <div style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                                        <Row className="mt-2">
                                            <Col>
                                                <h6><FormattedMessage id="SalesDashboard.CardTypes" /></h6>
                                            </Col>
                                            <Col>
                                                <h6><FormattedMessage id="SalesDashboard.Channels" /></h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Badge style={{ backgroundColor: this.state.colorCash }} className="px-2">&nbsp;</Badge>
                                                        <FormattedMessage id="SalesDashboard.Cash" /><br />
                                                        <Badge style={{ backgroundColor: this.state.colorService }} className="px-2">&nbsp;</Badge>
                                                        <FormattedMessage id="SalesDashboard.Services" />
                                                    </Col>
                                                    <Col>
                                                        <Badge style={{ backgroundColor: this.state.colorPms }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Pms" /><br />
                                                        <Badge style={{ backgroundColor: this.state.colorOnline }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Online" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </BlankCard>
                        </Col>
                        <Col>
                            <Row className="mb-2">
                                <Col className="col-3">
                                    <Card className="shadow" style={{ backgroundColor: '#f7f7f7', color: "#6c757d" }}>
                                        <CardBody>
                                            <h6><FormattedMessage id="SalesDashboard.Sold" /></h6>
                                            <div className="text-right font-weight-bold h5">
                                                <FormattedNumber value={this.state.totalSale || ''} style="currency" currency={'EUR'} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className=" col-3">
                                    <Card className="shadow" style={{ backgroundColor: this.state.colorCash, color: "#ffffff" }}>
                                        <CardBody>
                                            <h6><FormattedMessage id="SalesDashboard.Cash" /></h6>
                                            <div className="text-right font-weight-bold h5">
                                                <FormattedNumber value={this.state.totalCash || ''} style="currency" currency={'EUR'} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-3">
                                    <Card style={{ backgroundColor: this.state.colorService, color: "#ffffff" }}>
                                        <CardBody>
                                            <h6><FormattedMessage id="SalesDashboard.Services" /></h6>
                                            <div className="text-right font-weight-bold h5">
                                                <FormattedNumber value={this.state.totalCard || ''} style="currency" currency={'EUR'} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-3">
                                    <Card style={{ backgroundColor: this.state.colorDiscount, color: "#ffffff" }}>
                                        <CardBody>
                                            <h6><FormattedMessage id="SalesDashboard.Discount" /> </h6>
                                            <div>
                                                <span className=" font-weight-bold h5"> {((this.state.totalDiscount / this.state.totalSale) * 100).toFixed(2)} %</span>
                                                <span className="float-right font-weight-bold h5">
                                                    <FormattedNumber value={this.state.totalDiscount || ''} style="currency" currency={'EUR'} />
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3" >
                                    <Row className="mb-2">
                                        <Col >
                                            <BlankCard>
                                                <Pie height={210} data={dataPieCard} options={optionsPie} />
                                            </BlankCard>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <BlankCard>
                                                <Pie height={210} data={dataPieChannel} options={optionsPie} />
                                            </BlankCard>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <BlankCard>
                                                <Pie height={210} data={dataPieCnt} options={optionsPie} />
                                            </BlankCard>
                                        </Col>
                                    </Row>
                                </Col>
                    <Col className="col-9" >
                                <Row className="mb-2">
                                    <Col>
                                    <BlankCard>
                                        <Row>
                                            <Col className="col-12">

                                                    <Line data={objSalesType } options={optionsAmount} height={100} />
                                            </Col>
                                        </Row>
                                        </BlankCard>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <BlankCard>
                                            <Row>
                                                <Col className="col-12">

                                                    <Line data={objSalesChannel} options={optionsAmount} height={100} />
                                                </Col>
                                            </Row>
                                        </BlankCard>
                                    </Col>
                                </Row>
                        
                            </Col>
                        </Row>
                     </Col>
                    </Row>
                    : ''
                }
                {this.state.view === 'redeem' ?
                    <RedeemDashboard startDate={this.state.startDate} endDate={this.state.endDate} ref={this.redeemRef} />
                    : ''}
                {this.state.view === 'others' ?
                    <OthersDashboard startDate={this.state.startDate} endDate={this.state.endDate} ref={this.othersRef} />
                    : ''}
                <div className="mb-2" />
               
           </div>
        );
    }
}

export default injectIntl(SalesDashboard)
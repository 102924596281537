import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import classnames from 'classnames';
import { Col, Row, Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, CustomInput, Badge, UncontrolledPopover, PopoverHeader, PopoverBody, CardBody, Card, FormGroup, Label, Input } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getAPI, deleteAPI } from '../Base/API';
import { getVoucherTypes } from '../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../Base/Common/CustomSelect';
import { SelectHotel, CommonHelper, CancelationModal } from '../Base/Common/CommonUIComponents'
import { handleNotification } from "../Base/Common/Notification";
import AddVoucher from "../Operation/AddVoucher";
import { ErrorAlert } from "../Base/ErrorAlert";
import Authorization from '../Base/Authorization';
import { CashVoucherCard, RebateVoucherCard } from './VoucherCardDetail';
import ShoppingCartCheckOut from './ShoppingCartCheckOut';

export class VoucherManagment extends Component {
    static displayName = VoucherManagment.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.addVoucher = this.addVoucher.bind(this);
        this.unselectVoucher = this.unselectVoucher.bind(this);
        this.toggleShoppingCartCheckOut = this.toggleShoppingCartCheckOut.bind(this);
        this.state = {
            activeTab :'1',
            block: false,
            modal: false,
            hotelList: [],
            selectedHotel: null,
            isPayment: null,
            selectedVoucher: {},
            activeCashConfigs: [],
            activeRebateConfigs: [],
            inactiveCashConfigs: [],
            inactiveRebateConfigs: [],
            isShoppingCartMode: false,
            vouchersToSell: [],
            shoppingCartCheckOut: false,
            cancelationModal: false,
            soldVouchers: []
        }
    }

    componentDidMount() {

        this.getConfig();
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            soldVouchers: []
        }));
    }

    toggle(id) {
        this.setState({ activeTab : id });
    }

    shopVoucher(el,evt) {
        let payload = {
            voucherTypeId: el.id,
            salePrice : el.payedAmount,
            voucherValue: el.cardAmount,
            vouchersValidUntil: el.vouchersValidUntil,
            channels: el.channels
        }
        this.setState({ modal: true, selectedVoucher: payload});
    }

    getConfig() {
        this.setState({ block: true });
        let param = '';
        if (this.state.isPayment) {
            param =  (param === ''?  '?' : '&') + 'isPayment=' + this.state.isPayment
        }
        if (this.state.selectedHotel) {
            param = (param === '' ? '?' : '&') + 'hotelId=' + this.state.selectedHotel
        }
        
        getAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                else {
                    var activeCashConfigs = [], activeRebateConfigs = [], inactiveCashConfigs = [], inactiveRebateConfigs = []
                    data.response && data.response.forEach(config => {
                        if (config.active) {
                            if (config.isPaymentMode) {
                                activeCashConfigs.push(config);
                            }
                            else {
                                activeRebateConfigs.push(config);
                            }
                        }
                        else {
                            if (config.isPaymentMode) {
                                inactiveCashConfigs.push(config);
                            }
                            else {
                                inactiveRebateConfigs.push(config);
                            }
                        }
                    });
                    this.setState({ block: false, activeCashConfigs, activeRebateConfigs, inactiveCashConfigs, inactiveRebateConfigs });
                }
            }
        }, '/api/Voucher/V1/Config' + param);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    removeVoucher(id, evt) {
        
        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                handleNotification(data, <FormattedMessage id="VoucherManagement.VoucherConfigurationDeleted" />, <FormattedMessage id="VoucherManagement.Success" />);
                if (data.response === undefined)
                    this.setState({ block: false });
                else 
                    this.setState({ block: false }, () => this.getConfig());
            }
        }, '/api/Voucher/v1/Config/deleteVoucherType/' + id);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleSwitchChange(evt) {
        if (evt.target) {
            const { name, checked } = evt.target;
            this.setState({ [name]: checked });
        }
    }

    addVoucher(voucherTypeToAdd) {
        const { vouchersToSell } = this.state;
        const voucherType = vouchersToSell.find(v => v.voucherTypeId === voucherTypeToAdd.id);
        if (voucherType) {
            voucherType.quantity += 1;
            voucherType.voucherSalePrice = parseFloat(voucherType.quantity * voucherTypeToAdd.payedAmount).toFixed(2);
        }
        else {
            vouchersToSell.push(this.getVoucherToSell(voucherTypeToAdd));
        }
        this.setState({ vouchersToSell });
    }

    getVoucherToSell(voucherType) {
        const voucherToSell = {
            voucherTypeId: voucherType.id,
            quantity: 1,
            voucherSalePrice: parseFloat(voucherType.payedAmount).toFixed(2),
            code: voucherType.code,
            registerSalesInPms: voucherType.registerSalesInPms
        };
        return voucherToSell;
    }

    unselectVoucher(voucherTypeToRemove) {
        const { vouchersToSell } = this.state;
        const voucherType = vouchersToSell.find(v => v.voucherTypeId === voucherTypeToRemove.id);
        if (voucherType) {
            voucherType.quantity -= 1;
            if (voucherType.quantity === 0) {
                vouchersToSell.splice(vouchersToSell.indexOf(voucherType), 1);
            }
            else {
                voucherType.voucherSalePrice = parseFloat(voucherType.quantity * voucherTypeToRemove.payedAmount).toFixed(2);
            }
        }
        else {
            vouchersToSell.splice(vouchersToSell.indexOf(v => v.voucherTypeId === voucherTypeToRemove.id), 1);
        }
        this.setState({ vouchersToSell });
    }

    toggleShoppingCartCheckOut() {
        this.setState(prevState => ({ shoppingCartCheckOut: !prevState.shoppingCartCheckOut }));
    }

    toggleConfirmationModal = () => {
        if (this.state.soldVouchers.length > 0) {
            this.toggleModal();
        }
        else {
            this.setState(prevState => ({ cancelationModal: !prevState.cancelationModal }));
        }
    }

    exitFunc = () => {
        this.setState({ cancelationModal: false }, this.toggleModal)
    }

    setSoldVouchers = (newSoldVouchers) => {
        this.setState({ soldVouchers: newSoldVouchers });
    }

    render() {
        const { activeCashConfigs, activeRebateConfigs, inactiveCashConfigs, inactiveRebateConfigs, isShoppingCartMode, vouchersToSell, shoppingCartCheckOut, cancelationModal } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />

                { cancelationModal ?
                    <CancelationModal isOpen={cancelationModal} toggle={this.toggleConfirmationModal} exitFunc={this.exitFunc} />
                    : ''}

                <Modal isOpen={this.state.modal} toggle={this.toggleConfirmationModal} fade={false} className="modal-xl" >
                    <ModalBody className="modal-xl p-0">
                        <AddVoucher selectedVoucher={this.state.selectedVoucher} soldVouchers={this.state.soldVouchers} setSoldVouchers={this.setSoldVouchers}/>
                    </ModalBody>
                </Modal>

                {
                    shoppingCartCheckOut ?
                        <ShoppingCartCheckOut
                            isOpen={shoppingCartCheckOut}
                            toggle={this.toggleShoppingCartCheckOut}
                            vouchersToSell={vouchersToSell}
                            registerSalesInPms={vouchersToSell.filter(v => v.registerSalesInPms).length > 0}
                        />
                        :
                        <div />
                }

                <Row className="mb-2">
                    <Col className="col-2">
                        <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'selectedHotel')} value={this.state.selectedHotel} />
                    </Col>
                    <Col className="col-2">
                        <CustomSelect options={getVoucherTypes()} value={getVoucherTypes().filter(el => el.value === this.state.isPayment)} isClearable placeholder={<FormattedMessage id="generic.VoucherType" />} onChange={this.setComboStatus.bind(this, 'isPayment')} />
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="voucherManagement:add"
                            yes={() => (
                                <>
                                    <Button className="btn-host btn-sm mr-2" onClick={() => this.props.history.push('/CardConfigWizard')} id="createNewOffer"> <i className="fas fa-plus " /></Button>
                                    <UncontrolledTooltip placement="bottom" target="createNewOffer">
                                        <FormattedMessage id="VoucherManagement.CreateNewOffer" />
                                    </UncontrolledTooltip>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                        <Button className="btn-host btn-sm mr-2" onClick={this.getConfig.bind(this)}> <i className="fas fa-search " /></Button>
                        <CommonHelper help={<FormattedHTMLMessage id="VoucherManagement.Help" />} id={'voucherManagementHelp'} placement="bottom" />
                    </Col>
                </Row>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <FormattedMessage id="generic.active" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            <FormattedMessage id="generic.inactive" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" style={{ backgroundColor: "#ffffff" }}>
                        <Row className="mb-3 " />
                        <Row>
                            <Col>
                                <h5 className="ml-2">
                                    <FormattedMessage id="VoucherManagement.TypeCash" />
                                </h5>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="col-8 text-right">
                                        {
                                            isShoppingCartMode ?
                                                <span>
                                                    <Button id="ShoppingCartButton" className="btn-host btn-sm" onClick={this.toggleShoppingCartCheckOut}>
                                                        <i className="fas fa-shopping-cart" />
                                                    </Button>
                                                    <Badge style={{ position: 'relative', top: '-8px', right: '8px' }} className="badge backgound-host">
                                                        {vouchersToSell.reduce((acc, curr) => acc + curr.quantity, 0)}
                                                    </Badge>
                                                </span>
                                                :
                                                <span />
                                        }
                                    </Col>
                                    <Authorization
                                        perform="voucherManagement:shop"
                                        yes={() => (
                                            <Col className="col-4">
                                                <CustomInput
                                                    className="float-right mr-1"
                                                    type="switch"
                                                    id="isShoppingCartMode"
                                                    name="isShoppingCartMode"
                                                    label={<FormattedMessage id="VoucherManagement.ShoppingCartMode" />}
                                                    checked={isShoppingCartMode}
                                                    onChange={this.handleSwitchChange}
                                                />
                                            </Col>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            {
                                activeCashConfigs.map((el, k) =>
                                    <Col key={k} className="col-3 py-2">
                                        <CashVoucherCard
                                            el={el}
                                            history={this.props.history}
                                            removeVoucher={this.removeVoucher.bind(this, el.id)}
                                            shopVoucher={this.shopVoucher.bind(this, el)}
                                            isShoppingCartMode={isShoppingCartMode}
                                            addVoucher={this.addVoucher}
                                            unselectVoucher={this.unselectVoucher}
                                            vouchersToSell={vouchersToSell}
                                            icon="fas fa-wallet"
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                        <hr />
                        <h5 className="ml-2">
                            <FormattedMessage id="VoucherManagement.TypeRebates" />
                        </h5>
                        <Row className="mb-2">
                            {
                                activeRebateConfigs.map((el, k) =>
                                    <Col key={k} className="col-3 py-2">
                                        <RebateVoucherCard
                                            el={el}
                                            history={this.props.history}
                                            removeVoucher={this.removeVoucher.bind(this, el.id)}
                                            shopVoucher={this.shopVoucher.bind(this, el)}
                                            isShoppingCartMode={isShoppingCartMode}
                                            addVoucher={this.addVoucher}
                                            unselectVoucher={this.unselectVoucher}
                                            vouchersToSell={vouchersToSell}
                                            icon="fas fa-gift"
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" style={{ backgroundColor: "#ffffff" }}>
                        <Row className="mb-3" />
                        <h5 className="ml-2">
                            <FormattedMessage id="VoucherManagement.TypeCash" />
                        </h5>
                        <Row className="mb-2">
                            {
                                inactiveCashConfigs.map((el, k) =>
                                    <Col key={k} className="col-3 py-2">
                                        <CashVoucherCard
                                            el={el}
                                            history={this.props.history}
                                            removeVoucher={this.removeVoucher.bind(this, el.id)}
                                            shopVoucher={this.shopVoucher.bind(this, el)}
                                            isShoppingCartMode={isShoppingCartMode}
                                            addVoucher={this.addVoucher}
                                            unselectVoucher={this.unselectVoucher}
                                            vouchersToSell={vouchersToSell}
                                            icon="fas fa-wallet"
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                        <hr />
                        <h5 className="ml-2">
                            <FormattedMessage id="VoucherManagement.TypeRebates" />
                        </h5>
                        <Row className="mb-2">
                            {
                                inactiveRebateConfigs.map((el, k) =>
                                    <Col key={k} className="col-3 py-2">
                                        <RebateVoucherCard
                                            el={el}
                                            history={this.props.history}
                                            removeVoucher={this.removeVoucher.bind(this, el.id)}
                                            shopVoucher={this.shopVoucher.bind(this, el)}
                                            isShoppingCartMode={isShoppingCartMode}
                                            addVoucher={this.addVoucher}
                                            unselectVoucher={this.unselectVoucher}
                                            vouchersToSell={vouchersToSell}
                                            icon="fas fa-gift"
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                    </TabPane>
                </TabContent>
            </BlockUi>
        );
    }
}

import React, { Component } from 'react';
import { Badge, Card, Col, Input, Row, Button } from 'reactstrap';
import { StyledCard, SelectHotel } from '../Base/Common/CommonUIComponents';
import ReactHtmlParser from 'react-html-parser';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';
import { getFonts, getWidgetLanguages } from '../Base/Common/ReferenceDataFunctions';



export class VoucherPreview extends Component {
    static displayName = VoucherPreview.name;

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            color: '#0000ff',
            col: 3,
            mode: 'card',
            font: null,
            bgColor: '#ffffff',
            lang: 'en',

            voucherTexts: {},
            tec: {},
            excluded: null,
            included: null
        }
    }


    componentDidMount() {
        this.getTranslatedTexts();
    }


    getTranslatedTexts = () => {
        let voucherTexts = {}, tec = {}, excluded = null, included = null;

        //Title & Description
        if (this.props.voucherConfig.multiLingual && this.props.voucherConfig.multiLingual.length > 0) {

            if (this.state.lang === 'pt') {
                voucherTexts = this.props.voucherConfig.multiLingual.find(el => el.languageCode === 'PT');
            }
            else {
                voucherTexts = this.props.voucherConfig.multiLingual.find(el => el.languageCode === 'EN');
            }
        }

        //TEC
        if (this.props.voucherConfig.tec.multiLingual && this.props.voucherConfig.tec.multiLingual.length > 0) {

            if (this.state.lang === 'pt') {
                tec = this.props.voucherConfig.tec.multiLingual.find(el => el.languageCode === 'PT');
            }
            else {
                tec = this.props.voucherConfig.tec.multiLingual.find(el => el.languageCode === 'EN');
            }
        }

        //Included
        const includeMultiLingual = this.props.voucherTermsList.find(el => el.type === 'Included');

        if (includeMultiLingual && includeMultiLingual.multiLingual.length > 0) {
            if (this.state.lang === 'pt') {
                included = includeMultiLingual.multiLingual.find(el => el.languageCode === 'PT');
            }
            else {
                included = includeMultiLingual.multiLingual.find(el => el.languageCode === 'EN');
            }
        }

        //Excluded
        const excludedMultiLingual = this.props.voucherTermsList.find(el => el.type === 'Excluded');

        if (excludedMultiLingual && excludedMultiLingual.multiLingual.length > 0) {
            if (this.state.lang === 'pt') {
                excluded = excludedMultiLingual.multiLingual.find(el => el.languageCode === 'PT');
            }
            else {
                excluded = excludedMultiLingual.multiLingual.find(el => el.languageCode === 'EN');
            }
        }

        this.setState({ voucherTexts, tec, excluded, included });
    }

    getTranslatedRebate = (multiLingual) => {
        let object = null;

        if (this.state.lang === 'pt') {
            object = multiLingual.find(el => el.languageCode === 'PT').description;
        }
        else {
            object = multiLingual.find(el => el.languageCode === 'EN').description;
        }

        return object;
    }

    isDisabled = () => {
        if (this.state.col && this.state.color && this.state.font && this.state.hotelId) {
            return false;
        }

        return true;
    }

    handleLanguage = (combo) => {
        this.setState({
            lang: combo ? combo.value : null
        }, this.getTranslatedTexts)
    }

    render() {
        const { col, color, font, lang, hotelId, voucherTexts, tec, excluded, included, bgColor } = this.state;

        const code = `
            <div>
			    <script>
				    const params = new URLSearchParams(document.location.search);
				    const session_id = params.get("session_id");
				    const id = params.get("id");
				    const iframe = document.createElement('iframe');
				    iframe.src = "${process.env.REACT_APP_CONFIG_WIDGET_JS_URL}?columns=${col}&hotelId=${hotelId}&color='${encodeURIComponent(color)}'&backgroundColor='${encodeURIComponent(bgColor)}'&font='${font}'&lang=${lang}&redirectUrl='"+document.URL+"'&session_id='"+session_id+"'&id='"+id+"'";
                    iframe.style.width = "100%";
                    iframe.style.height = "100%";
                    iframe.style.border = "0";
                    document.body.appendChild(iframe);										
			    </script>
		    </div>
        `;

        return (

            <StyledCard title="VoucherPreview.VoucherPreview">
                <Row>
                    <Col className="col-3 ">
                        <div>Hotel</div>
                        <SelectHotel onChangeFunc={(combo) => this.setState({ hotelId: combo ? combo.value : null })} value={this.state.hotelId} />
                    </Col>
                </Row>
                <Row className="my-3 align-items-end">
                    <Col>
                        <div>Num Col</div>
                        <Input type="number" min="1" max="4" value={this.state.col} placeholder="Num Col" onChange={(e) => this.setState({ col: e.target.value })}></Input>
                    </Col>
                    <Col>
                        <div>Background Color</div>
                        <Input type="color" value={this.state.bgColor} placeholder="Background Color" onChange={(e) => this.setState({ bgColor: e.target.value })}></Input>
                    </Col>
                    <Col>
                        <div>Color</div>
                        <Input type="color" value={this.state.color} placeholder="Color" onChange={(e) => this.setState({ color: e.target.value })}></Input>
                    </Col>
                    <Col>
                        <div>Language</div>
                        <CustomSelect
                            placeholder="Language"
                            options={getWidgetLanguages(this.props.intl)}
                            onChange={(combo) => this.handleLanguage(combo)}
                            value={getWidgetLanguages(this.props.intl).find(lang => lang.value === this.state.lang) || ''}
                        />
                    </Col>
                    <Col>
                        <div>Font</div>
                        <CustomSelect
                            placeholder="Font"
                            options={getFonts()}
                            onChange={(combo) => this.setState({ font: combo && combo.value })}
                            value={getFonts().find(font => font.value === this.state.font) || ''}
                        />
                    </Col>
                    <Col className="col-1 text-right mb-1">
                        <Button className="btn-sm btn-host" onClick={() => navigator.clipboard.writeText(code)} disabled={this.isDisabled()}><i className="fas fa-code"></i></Button>
                    </Col>                    
                </Row>
                
                {this.state.mode === 'card' ?
                    <Row style={{ fontFamily: this.state.font, backgroundColor: this.state.bgColor }} className="py-3">
                        <Col className={'col-' + (12 / this.state.col)}>
                            <Card style={{ cursor: 'pointer' }} onClick={() => this.setState({ mode : 'details' })}>
                            <div style={{ width: '100%', minHeight: '300px', backgroundSize: 'cover', backgroundImage: (this.props.voucherImageList.length > 0 ? `url("${this.props.voucherImageList[0].url}")` : '') }}>
                                {this.props.voucherConfig.cardAmount && this.props.voucherConfig.payedAmount && this.props.voucherConfig.cardAmount !== this.props.voucherConfig.payedAmount ? 
                                    <div className="text-right m-2">
                                        <Badge className="p-1" style={{ fontSize: 'xx-large', backgroundColor: this.state.color }} >{Math.round((1 - (this.props.voucherConfig.payedAmount / this.props.voucherConfig.cardAmount)) * 100)}%</Badge>
                                    </div>
                                    :''}
                            </div>
                            <div className="m-2" style={{ height : '150px' }}>
                                    <Row className="mb-1">
                                    <Col >
                                        <span className="store-card-header" style={{ fontFamily: this.state.font }}> {voucherTexts ? voucherTexts.title : ''}</span>
                                        </Col>
                                    </Row>
                                <Row className="mb-1">
                                    <Col className="store-card-text" style={{ maxHeight: '100px', fontFamily: this.state.font }}>
                                        {voucherTexts ? voucherTexts.description : ''}
                                    </Col>
                                </Row>
                                    
                                   
                                <div className="bg-white my-1" style={{ position: 'absolute', bottom: '0px', right: '0px', width: '100%', boxShadow: '0 -20px 13px 0 #ffffffa6', fontFamily: this.state.font }}>
                                    <Row className="px-2 mb-2">
                                        <Col>
                                            <b>{this.props.voucherConfig.payedAmount} €</b>
                                        </Col>
                                        <Col className="text-right">
                                            <Button className="btn btn-sm" style={{ backgroundColor: this.state.color, borderColor: this.state.color }}> <i className="fas fa-cart-arrow-down"></i></Button>
                                        </Col>
                                    </Row>
                                    </div>
                                       
                                </div>
                            </Card>
                        </Col>
                    </Row>
                  
                    :
                    <div style={{ fontFamily: this.state.font, backgroundColor: this.state.bgColor }}>
                        <Row>
                            <Col className="col-3">
                                {this.props.voucherImageList && this.props.voucherImageList.map((el, k) =>
                                    <div className="mb-1" key={k}>
                                        <img src={el.url } width="100%"></img>
                                    </div>
                                    )}
                                
                            </Col>
                            <Col className="col-6">
                                <div className="store-card-header" style={{ fontFamily: this.state.font }}>{voucherTexts ? voucherTexts.title : ''}</div>
                                <div className="mb-2 store-card-text" style={{ fontFamily: this.state.font }}>{voucherTexts ? voucherTexts.description : ''}</div>
                                <Row className="my-2">
                                    <Col className="store-card-header">
                                        <i className="fas fa-calendar-check mr-2" style={{ color: this.state.color }}></i><span style={{ fontFamily: this.state.font }}> <FormattedMessage id="generic.validUntil" /> {moment().add(this.props.voucherConfig.vouchersValidUntil, 'month').format('YYYY-MM-DD')}</span>
                                    </Col>                                   
                                </Row>
                                {this.props.voucherRebateList && this.props.voucherRebateList.length > 0 ?
                                    <Row>
                                        <Col className="store-card-header" style={{ fontFamily: this.state.font }}> <FormattedMessage id="PrintableVoucher.IncludedServices" /></Col>
                                    </Row>
                                    :''}
                                {this.props.voucherRebateList && this.props.voucherRebateList.map((item, k) =>
                                    <div className="store-card-text" key={k} style={{ fontFamily: this.state.font }}>
                                        <Row className="mb-2">
                                            <Col className=" store-card-text" style={{ fontFamily: this.state.font }}>
                                                { this.getTranslatedRebate(item.multiLingual)}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className=""><FormattedMessage id="EditVoucher.Quantity" /> </Col>
                                            <Col className="">{item.quantity}</Col>
                                        </Row>
                                        <Row >
                                            <Col className=""><FormattedMessage id="EditVoucher.UsagePeriod" /></Col>
                                            <Col className="">{item.redeemValidFrom} até {item.redeemValidTo}</Col>
                                        </Row>
                                        <hr className="my-1" />
                                    </div>    
                                )}
                                {included && included.description ?
                                    <div style={{ fontFamily: this.state.font }}>
                                        <Row className="my-2">
                                            <Col className="store-card-header">
                                                <i className="fas fa-check text-success " /><span style={{ fontFamily: this.state.font }}> <FormattedMessage id="EditVoucher.Included" /> </span>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="store-card-text" style={{ fontFamily: this.state.font }}>
                                                {ReactHtmlParser("<li>" + included.description.replace(/\n/g, "</li><li>") + '</li>') }
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    ''
                                }

                                {excluded && excluded.description ?
                                    <>
                                        <Row className="my-2">
                                            <Col className="store-card-header">
                                                <i className="fas fa-times text-danger" /> <span style={{ fontFamily: this.state.font }}> <FormattedMessage id="EditVoucher.NotIncluded" /></span>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="store-card-text" style={{ fontFamily: this.state.font }}>
                                                
                                                { ReactHtmlParser("<li>" + excluded.description.replace(/\n/g, "</li><li>") + '</li>')}
                                                                                                   
                                            </Col>
                                        </Row>
                                    </>
                                : '' }
                                <Row className="my-2">
                                    <Col className="store-card-header"><i className="fas fa-file-contract mr-2 " style={{ color: this.state.color }}></i><span style={{ fontFamily: this.state.font }}> <FormattedMessage id="EditVoucher.TermsAndConditions" /> </span></Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="store-card-text" style={{ whiteSpace: 'break-spaces', fontFamily: this.state.font }}>

                                        {tec.description && ReactHtmlParser("<li>" + tec.description.replace(/\n/g, "</li><li>") + '</li>')}
                                    </Col>
                                </Row>


                                <Row className="mb-2">
                                    <Col className="store-card-header"><i className="fas fa-hotel mr-2 " style={{ color: this.state.color }}></i><span style={{ fontFamily: this.state.font }}> <FormattedMessage id="VoucherPreview.RedeemLocations" /> </span></Col>
                                </Row>
                                {this.props.voucherHotelList && this.props.voucherHotelList.map((el, k) =>
                                    <Row className="mb-2" key={k}>
                                        <Col style={{ fontFamily: this.state.font }}>
                                            {el.name}
                                            
                                    </Col>
                                    </Row>

                                    )}
                            </Col> 
                            <Col className="col-3">
                                <Row className="my-2">
                                    <Col>{this.props.voucherConfig.cardAmount && this.props.voucherConfig.payedAmount !== this.props.voucherConfig.cardAmount ? 
                                        <span className="text-muted"><del>{this.props.voucherConfig.cardAmount} €</del></span>
                                        :''}
                                        <span ><h4>{this.props.voucherConfig.payedAmount} €</h4></span>
                                    </Col>
                                    <Col className="text-right">
                                        {this.props.voucherConfig.cardAmount && this.props.voucherConfig.payedAmount && this.props.voucherConfig.cardAmount !== this.props.voucherConfig.payedAmount ?
                                            <div className="text-right m-2">
                                                <Badge className="p-1" style={{ fontSize: 'xx-large', backgroundColor: this.state.color }} >{Math.round((1-(this.props.voucherConfig.payedAmount / this.props.voucherConfig.cardAmount  )) * 100)}%</Badge>
                                            </div>
                                            : ''}
                                      
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-sm" style={{ backgroundColor: this.state.color, borderColor: this.state.color }}> <i className="fas fa-cart-arrow-down"></i></Button>
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                       
                        
                    </div>
                }
            </StyledCard>


        );
    }
}

import React, { Component } from 'react';
import { Modal, Button, Progress, Row, Col, CardBody, Card, Badge } from 'reactstrap';
import { getAPI } from '../Base/API';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';
import { SelectHotel, BlankCard } from '../Base/Common/CommonUIComponents';
import { Pie, Line } from 'react-chartjs-2';
import moment from "moment";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export class OthersDashboard extends Component {
    static displayName = OthersDashboard.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            colorCreated: '#7ddf64',
            colorCancel: '#4d4d4d',
            colorRefunded: '#db231c',
            colorCash: '#AAB71A',
            colorService: '#708843'
            
            
        }
    }

    componentDidMount() {
        this.getOthers();

    }

    getOthers() {
        this.setState({ block: true });
        let param = '?fromDate=' + this.props.startDate.format('YYYY-MM-DD') + '&toDate=' + this.props.endDate.format('YYYY-MM-DD');

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                console.log(data)
                let totalCancelCash = 0;
                let totalCancelServices = 0;
                let totalCreatedCash = 0;
                let totalCreatedServices = 0;
                let totalRefundCash = 0;
                let totalRefundServices = 0;

                let amountCancelCash = 0;
                let amountCancelServices = 0;
                let amountCreatedCash = 0;
                let amountCreatedServices = 0;
                let amountRefundCash = 0;
                let amountRefundServices = 0;


                let cardSummaryCreated = [];
                let cardSummaryCanceled = [];
                let cardSummaryRefunded = [];

                let dailyCreated = [];
                let dailyCanceled = [];
                let dailyRefunded = [];
                


                data.response.map((el, k) => {

                    if (el.status === 0) {
                        if (el.isPay === false) {
                            totalCreatedServices += el.count;
                            amountCreatedServices += el.amount;
                        } else {
                            totalCreatedCash += el.count;
                            amountCreatedCash += el.amount;
                        }
                        let cardsum = cardSummaryCreated.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryCreated.push(el)
                        } else {
                            cardsum.amount += el.amount;
                            cardsum.count += el.count;
                        }

                        let auxService = dailyCreated.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyCreated.push({ x: moment(el.createdAt).toDate(), y: el.amount });
                        } else {
                            dailyCreated[auxService] = { x: dailyCreated[auxService].x, y: el.amount + dailyCreated[auxService].y };
                        }


                    }
                    else if (el.status === 30) {
                        if (el.isPay === false) {
                            totalRefundServices += el.count;
                            amountRefundServices += el.amount;
                        } else {
                            totalRefundCash += el.count;
                            amountRefundCash += el.amount;
                        }
                        let cardsum = cardSummaryRefunded.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryRefunded.push(el)
                        } else {
                            cardsum.amount += el.amount;
                            cardsum.count += el.count;
                        }
                        let auxService = dailyRefunded.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyRefunded.push({ x: moment(el.createdAt).toDate(), y: el.amount });
                        } else {
                            dailyRefunded[auxService] = { x: dailyRefunded[auxService].x, y: el.amount + dailyRefunded[auxService].y };
                        }

                    }
                    else if (el.status === 99) {
                        if (el.isPay === false) {
                            totalCancelServices += el.count;
                            amountCancelServices += el.amount;
                        } else {
                            totalCancelCash += el.count;
                            amountCancelCash += el.amount;
                        }
                        let cardsum = cardSummaryCanceled.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryCanceled.push(el)
                        } else {
                            cardsum.amount += el.amount;
                            cardsum.count += el.count;
                        }
                        let auxService = dailyCanceled.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyCanceled.push({ x: moment(el.createdAt).toDate(), y: el.amount });
                        } else {
                            dailyCanceled[auxService] = { x: dailyCanceled[auxService].x, y: el.amount + dailyCanceled[auxService].y };
                        }
                    }

                    /*if (el.isPay === false) {
                        totalRedeemServices += el.count;
                        let cardsum = cardSummaryService.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryService.push(el)
                        } else {
                            cardsum.salePrice += el.salePrice;
                            cardsum.count += el.count;
                        }

                        let auxService = dailyRedeemsService.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyRedeemsService.push({ x: moment(el.createdAt).toDate(), y: el.count });
                        } else {
                            dailyRedeemsService[auxService] = { x: dailyRedeemsService[auxService].x, y: el.count + dailyRedeemsService[auxService].y };
                        }

                        if (el.revCategory != null) {
                            let idx = revCategoryName.findIndex( x => x === el.revCategory);
                            if (idx < 0) {
                                revCategoryName.push(el.revCategory);
                                revCategoryAmount.push(el.count);
                            } else {
                                revCategoryAmount[idx] += el.count;
                            }

                        }
                        

                    } else {
                        totalRedeemCash += el.reddemsAmount;
                        
                        let cardsum = cardSummaryCash.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryCash.push(el)
                        } else {
                            cardsum.salePrice += el.salePrice;
                            cardsum.reddemsAmount += el.reddemsAmount;
                            cardsum.count += el.count;
                        }
                        let auxService = dailyRedeemsCash.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyRedeemsCash.push({ x: moment(el.createdAt).toDate(), y: el.reddemsAmount });
                        } else {
                            dailyRedeemsCash[auxService] = { x: dailyRedeemsCash[auxService].x, y: el.reddemsAmount + dailyRedeemsCash[auxService].y };
                        }


                    }*/

                    


                })
               
                this.setState({
                    totalCancelCash: totalCancelCash,
                    totalCancelServices: totalCancelServices,
                    totalCreatedCash:totalCreatedCash,
                    totalCreatedServices: totalCreatedServices,
                    totalRefundCash : totalRefundCash,
                    totalRefundServices: totalRefundServices,

                    amountCancelCash :amountCancelCash,
                    amountCancelServices: amountCancelServices,
                    amountCreatedCash: amountCreatedCash,
                    amountCreatedServices: amountCreatedServices,
                    amountRefundCash: amountRefundCash,
                    amountRefundServices: amountRefundServices,

                    cardSummaryCreated: cardSummaryCreated.sort((a, b) => b.amount - a.amount),
                    cardSummaryCanceled: cardSummaryCanceled.sort((a, b) => b.amount - a.amount),
                    cardSummaryRefunded: cardSummaryRefunded.sort((a, b) => b.amount - a.amount),

                    dailyCreated: dailyCreated.sort((a, b) => a.x - b.x),
                    dailyRefunded: dailyRefunded.sort((a, b) => a.x - b.x),
                    dailyCanceled: dailyCanceled.sort((a, b) => a.x - b.x),

                     /*totalRedeemServices: totalRedeemServices,
                    totalRedeemCash: totalRedeemCash,
                    cardSummaryCash: cardSummaryCash.sort((a, b) => b.reddemsAmount - a.reddemsAmount),
                    cardSummaryService: cardSummaryService.sort((a, b) => b.count - a.count),
                    dailyRedeemsCash: dailyRedeemsCash.sort((a, b) => a.x - b.x),
                    dailyRedeemsService: dailyRedeemsService.sort((a, b) => a.x - b.x),
                    revCategoryAmount: revCategoryAmount,
                    revCategoryName: revCategoryName,*/
                    block: false 
                });
            }
        }, '/api/Voucher/Dashboard/v1/OthersStatus' + param);
            }


    render() {


        let objCreated = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Created" }),
                        "data": this.state.dailyCreated ? this.state.dailyCreated : [],
                        "fill": false,
                        "borderColor": this.state.colorCreated,
                        "backgroundColor": this.state.colorCreated+'11',
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    }


                ]
        };

        let objRefunded = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Refunded" }),
                        "data": this.state.dailyRefunded ? this.state.dailyRefunded : [],
                        "fill": false,
                        "borderColor": this.state.colorRefunded,
                        "backgroundColor": this.state.colorRefunded+ '22',
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ]
        };

        let objCanceled= {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Canceled" }),
                        "data": this.state.dailyCanceled ? this.state.dailyCanceled : [],
                        "fill": false,
                        "borderColor": this.state.colorCancel,
                        "backgroundColor": this.state.colorCancel + '22',
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ]
        };

        let optionsAmount = {
            legend: {
                position: "left",
                display: false,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            quarter: 'MMM D'
                        }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {

                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }


        return (


            <Row>
                <Col className="col-4" >
                    <BlankCard icon={'fas fa-money-bill-alt'} title={'Sales '} error={this.state.error} block={this.state.block}>
                        <div style={{ paddingBottom: '5rem' }}> 
                            <h4 className="mb-4"><FormattedMessage id="SalesDashboard.TopCreated" /></h4>

                            {this.state.cardSummaryCreated && this.state.cardSummaryCreated.map((s, k) =>
                            <div key={k}>
                                <Row className="mb-2">
                                    <Col>
                                        <Row>
                                            <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                <b>{s.count} x {s.code}</b>

                                            </Col>
                                            <Col className="col-3 text-right">

                                                <FormattedNumber
                                                    value={s.amount}
                                                    style="currency" currency={'EUR'} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                    <Progress color={s.isPay ? 'cash' : 'service'} value={(s.amount / (this.state.amountCreatedCash + this.state.amountCreatedServices ) ) * 100}  ></Progress>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                                <hr className="my-1" />
                            </div>
                            )}
                            {this.state.cardSummaryCanceled && this.state.cardSummaryCanceled.length > 0 ?
                                <>
                                    <h4 className="mb-4"><FormattedMessage id="SalesDashboard.TopCanceled" /></h4>
                                    {this.state.cardSummaryCanceled && this.state.cardSummaryCanceled.map((s, k) =>
                                        <div key={k}>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Row>
                                                        <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                            <b>{s.count} x {s.code}</b>

                                                        </Col>
                                                        <Col className="col-3 text-right">
                                                            <FormattedNumber
                                                                value={s.amount}
                                                                style="currency" currency={'EUR'} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Progress color={s.isPay ? 'cash' : 'service'} value={(s.amount / (this.state.amountCancelCash + this.state.amountCancelServices)) * 100}  ></Progress>
                                                        </Col>

                                                    </Row>

                                                </Col>
                                            </Row>
                                            <hr className="my-1" />
                                        </div>
                                    )}
                                </>
                                : ''}

                            {this.state.cardSummaryRefunded && this.state.cardSummaryRefunded.length > 0 ?
                                <>
                            <h4 className="mb-4"><FormattedMessage id="SalesDashboard.TopRefunded" /></h4>
                            {this.state.cardSummaryRefunded && this.state.cardSummaryRefunded.map((s, k) =>
                                <div key={k}>
                                    <Row className="mb-2">
                                        <Col>
                                            <Row>
                                                <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                    <b>{s.count} x {s.code}</b>

                                                </Col>
                                                <Col className="col-3 text-right">
                                                      <FormattedNumber
                                                    value={s.amount}
                                                    style="currency" currency={'EUR'} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Progress color={s.isPay ? 'cash' : 'service'} value={(s.amount / (this.state.amountRefundCash + this.state.amountRefundServices)) * 100}  ></Progress>
                                                </Col>

                                            </Row>

                                        </Col>
                                    </Row>
                                    <hr className="my-1" />
                                </div>
                                    )}
                                </>
                                : ''}

                        <div style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                            <Row className="mt-2">
                                <Col><h6><FormattedMessage id="RedeemDashboard.CardTypes" /></h6></Col>
                                <Col><h6></h6></Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Row>
                                        <Col>
                                            <Badge style={{ backgroundColor: this.state.colorCash }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Cash" /><br />
                                            <Badge style={{ backgroundColor: this.state.colorService }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Services" />
                                            </Col>
                                        <Col>
                                            
                                            </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </div>
                        </div>




                    </BlankCard>
                </Col>
                <Col className="col-8">
                    <Row className="mb-2">
                     
                        <Col className=" col-4">
                            <Card className="shadow" style={{ backgroundColor: this.state.colorCreated, color: "#ffffff" }}>
                                <CardBody>
                                    <h6><FormattedMessage id="SalesDashboard.Created" /></h6>
                                    <div className=" font-weight-bold h5">
                                        <span># {this.state.totalCreatedCash + this.state.totalCreatedServices}</span>
                                        <span className="float-right">
                                        <FormattedNumber
                                            value={this.state.amountCreatedCash + this.state.amountCreatedServices}
                                                style="currency" currency={'EUR'} />
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-4">
                            <Card style={{ backgroundColor: this.state.colorCancel, color: "#ffffff" }}>
                                <CardBody>
                                    <h6><FormattedMessage id="SalesDashboard.Cancel" /></h6>
                                    <div className=" font-weight-bold h5">
                                        <span># {this.state.totalCancelCash + this.state.totalCancelServices}</span>
                                        <span className="float-right">
                                    <FormattedNumber
                                        value={this.state.amountCancelCash + this.state.amountCancelServices}
                                                style="currency" currency={'EUR'} />
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-4">
                            <Card style={{ backgroundColor: this.state.colorRefunded, color: "#ffffff" }}>
                                <CardBody>
                                    <h6><FormattedMessage id="SalesDashboard.Refund" /></h6>
                                    <div className=" font-weight-bold h5">
                                        <span># {this.state.totalRefundCash + this.state.totalRefundServices}</span>
                                        <span className="float-right">
                                    <FormattedNumber
                                        value={this.state.amountRefundCash + this.state.amountRefundServices}
                                                style="currency" currency={'EUR'} />
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                      
                        <Col className="col-12" >
                            <Row className="mb-2">
                                <Col>
                                    <BlankCard>
                                        <Row>
                                            <Col className="col-12">

                                                <Line data={objCreated} options={optionsAmount} height={50} />
                                            </Col>
                                        </Row>
                                    </BlankCard>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    
                                        <Row>
                                            <Col className="col-12">
                                            <BlankCard>
                                                <Line data={objCanceled} options={optionsAmount} height={50} />
                                            </BlankCard>
                                            </Col>
                                       

                                        </Row>

                                    
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>

                                    <Row>
                                        <Col className="col-12">
                                            <BlankCard>
                                                <Line data={objRefunded} options={optionsAmount} height={50} />
                                            </BlankCard>
                                        </Col>


                                    </Row>


                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>

        );
    }
}

export default injectIntl(OthersDashboard)
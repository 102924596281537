import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Badge} from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { processStatusValues } from './CommonUIComponents';

class TimelineElement extends Component {
    constructor(props) {
        super(props);
        this.onVisibilitySensorChange = this.onVisibilitySensorChange.bind(this);
        this.state = { visible: false };
    }

    onVisibilitySensorChange(isVisible) {
        if (isVisible) {
            this.setState({ visible: true });
        }
    }
    

    render() {
        const {
            id,
            children,
            icon,
            iconStyle,
            iconOnClick,
            date,
            position,
            style,
            className,
            eventId,
            lastSelected,
            user,
            processStatus
        } = this.props;

        return (
            <div
                id={id}
                className={classNames(className, 'timeline-element', {
                    'timeline-element--left': position === 'left',
                    'timeline-element--right': position === 'right',
                    'timeline-element--no-children': children === '',
                })}
                style={style}
            >
                <div >
                    <span
                        style={iconStyle}
                        onClick={iconOnClick}
                        className='timeline-element-icon'
                    >
                        {icon}
                    </span>
                    <div className='timeline-element-content'>
                        <div className="mb-0 mt-0 "  >
                            <Row>
                                <Col>
                                    <Row style={{ minHeight: '10px' }} >
                                        <Col className="col-12">
                                            <span className="timeline-element-date">{date} | {user}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ minHeight: '10px' }} >
                                        <Col className="col-12">
                                            {children}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-3">
                                    <Button className={'float-right btn-host shadow btn-sm mt-1 ' + (eventId === lastSelected ? 'active' : '')} id="PopoverFocus" onClick={this.props.viewEventDetails.bind(this, eventId)}>
                                        <i className="fas fa-info-circle" />
                                    </Button>
                                </Col>
                            </Row>
                            <hr className="mb-0 mt-1" />
                        </div>    
                    </div>
                </div>
            </div>
        );
    }
}
TimelineElement.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    icon: PropTypes.element,
    iconStyle: PropTypes.shape({}),
    iconOnClick: PropTypes.func,
    style: PropTypes.shape({}),
    date: PropTypes.node,
    position: PropTypes.string,
    lastSelected: PropTypes.number
};
TimelineElement.defaultProps = {
    id: '',
    children: '',
    className: '',
    icon: null,
    iconStyle: null,
    style: null,
    date: '',
    position: '',
    lastSelected: '',
    iconOnClick: null
};

export default TimelineElement;
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { getAPI } from '../../Base/API';
import {  StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { HotelConfigDetails } from "./HotelConfigDetails";
import moment from "moment";
import HotelGroupConnections from './HotelGroupConnections';


export class HotelConfig extends Component {
    static displayName = HotelConfig.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            reservationList: [],
            currentPage: 0,
            PageSize: 10,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            modal: false
        }
    }


    componentDidMount() {
        this.getHotelConfig();
    }

    getHotelConfig() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {

                    this.setState({ hotelList: data.response[0].hotels });
                }
                this.setState({ block: false });
            }
        }, `/api/Voucher/Hotel/v1/Hotel`);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }
    
    handleSelect(combo) {
        this.setState({
            selectedRule: combo ? combo.value : null
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedHotelConfig: null,
        }));
    }

    updateTable = (config) => {
        this.setState({ block: true });
        const index = this.state.hotelList && this.state.hotelList.indexOf(this.state.hotelList.find(c => c.hotelId === config.hotelId));

        if (index !== -1) {
            var hotelList = [...this.state.hotelList];
            hotelList[index].hotelConfig = config;

            this.setState({ hotelList });
        }
        this.setState({ block: false });
    }


    render() {
        const columns = [
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "HotelConfig.HotelId" }),
                sort: true
            },
            {
                dataField: 'name2',
                text: this.props.intl.formatMessage({ id: "HotelConfig.HotelName" }),
                sort: true
            },
            {
                dataField: 'hotelConfig.automaticVoucherIssue',
                text: this.props.intl.formatMessage({ id: "HotelConfig.AutomaticVoucherIssue" }),
                sort: true,
                formatter: (cell, row) => row.hotelConfig ? row.hotelConfig.automaticVoucherIssue ? <Badge color="primary"> <FormattedMessage id="generic.active" /> </Badge> : <Badge color="danger"> <FormattedMessage id="generic.inactive" /> </Badge> : ''
            },
            {
                dataField: 'hotelConfig.registerSalesInPms',
                text: this.props.intl.formatMessage({ id: "HotelConfig.RegisterSalesInPms" }),
                sort: true,
                formatter: (cell, row) => row.hotelConfig ? row.hotelConfig.registerSalesInPms ? <Badge color="primary"> <FormattedMessage id="generic.active" /> </Badge> : <Badge color="danger"> <FormattedMessage id="generic.inactive" /> </Badge> : ''
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    selectedHotelConfig: row,
                }));
            }
        };


        return (
            <div>
                <StyledCard icon={'fas fa-hotel'} title={'HotelConfig.Title'} help={<FormattedMessage id="HotelConfig.Help" />} id="hotelConfig" error={this.state.error} block={this.state.block}>
                    <CustomTable
                        keyField={"hotelId"}
                        data={this.state.hotelList ? this.state.hotelList : []}
                        columns={columns}
                        shadow={false}
                        showTotal={true}
                        remote={false}
                        selectRow={selectRow}
                    />

                    {this.state.modal ?
                        <HotelConfigDetails
                            modal={this.state.modal}
                            toggle={this.toggleModal}
                            selectedHotelConfig={this.state.selectedHotelConfig}
                            updateTable={this.updateTable}
                        />
                        : ''}
                </StyledCard>


                <div className="mt-3">
                    <HotelGroupConnections />
                </div>
            </div>
        );
    }
}

export default injectIntl(HotelConfig);
import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Col, Row, Badge, Button, Modal, ModalBody, ModalHeader, Input, UncontrolledTooltip, Label } from 'reactstrap';
import { getAPI, postAPI } from '../Base/API';
import { handleNotification } from "../Base/Common/Notification";
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { getRightVoucherId, getStripeRefundReasons } from '../Base/Common/ReferenceDataFunctions';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import CustomSelect from '../Base/Common/CustomSelect';
import Authorization from '../Base/Authorization';
import { RegistSaleOnPmsModal } from './RegistSaleOnPmsModal';
import NotificationCard from './NotificationCard';

export class OnlineSalesDetail extends Component {
    static displayName = OnlineSalesDetail.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleRegistSaleOnPmsModal = this.toggleRegistSaleOnPmsModal.bind(this);
        this.form = React.createRef();
        this.state = {
            block: true,
            modal : false,
            onlinePayment: {},
            registSaleOnPmsModal: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getOnlinePaymentsDetails(this.props.match.params.id)

    }

    getOnlinePaymentsDetails = (id) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ onlinePayment: data.response.length > 0 ? data.response[0] : [],  block: false });
            }
        }, '/api/Voucher/Voucher/v1/onlineSale/' + id);
    }

    checkPaymentUpdates() {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="OnlineSalesDetail.SyncPaymentSuccessfully" />, <FormattedMessage id="OnlineSalesDetail.Success" />);
                    this.setState({ onlinePayment: data.response[0] });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/Voucher/Payment/v1/ReconcilePayment/' + this.props.match.params.id);
    }

    postRefund(e) {

        e.preventDefault();

        if (this.form.current.reportValidity()) {

            this.setState({ block: true });
            let req = {
                "Id": this.state.selectedVoucher.id,
                "reason": this.state.reason,
                "notes": this.state.notes,
                "amount": this.state.amount
            }
            postAPI(result => {
                const { data, error } = result;



                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="OnlineSalesDetail.RefundSuccessfully" />, <FormattedMessage id="OnlineSalesDetail.Success" />);
                    this.setState({ TODO: data.response[0], block: false });
                }
            }, '/api/Voucher/Payment/v1/Refund', req);

        }
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    voucherDetail(id) { this.props.history.push('/VoucherOperationDetails/' + id)}

    refund(e, id) {
        this.setState({ modal: true, selectedVoucher: this.state.onlinePayment.vouchers.find(el => el.id === id) })

    }

    updateFilterCombo(val, evt) {
        
        if (evt) {
            let name = evt.name;
            let value = val ? val.value : val;

            this.setState({ [name]: value });
        }
    }

    sendNotification() {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="OnlineSalesDetail.NotificationSentSuccessfully" />, <FormattedMessage id="OnlineSalesDetail.Success" />);

                    this.setState({ block: false }, () => this.getOnlinePaymentsDetails(this.props.match.params.id));
                }
            }
            else this.setState({ block: false });
        }, '/api/Voucher/v1/Notification/sendOnlineSaleNotification/' + this.state.onlinePayment.id);

    }

    toggleRegistSaleOnPmsModal() {
        this.setState(prevState => ({
            registSaleOnPmsModal: !prevState.registSaleOnPmsModal
        }))
    }

    render() {
        const { onlinePayment, registSaleOnPmsModal } = this.state;

        const isRegisterOnPmsDisabled = this.state.onlinePayment.vouchers && this.state.onlinePayment.vouchers.length > 0 && this.state.onlinePayment.vouchers.find(v => v.invoiceReference !== null && v.pmsInvoiceURL);
        
        const help = <div> </div>
        return (

            <>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModal}>
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <StyledCard title={'OnlineSalesDetail.Refund'} block={this.state.block}>
                            <form ref={this.form}>
                                <Row className="mb-2">
                                    <Col>
                                        <b><FormattedMessage id="OnlineSalesDetail.Voucher" />{this.state.selectedVoucher && getRightVoucherId(this.state.selectedVoucher.voucherId, this.state.selectedVoucher.voucherIdFirst8)}</b>
                                    </Col>
                                <Col className="text-right">
                                        <Button className="btn-sm btn-host" onClick={this.postRefund.bind(this)} ><i className="fas fa-save"></i></Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                    <Col className="col-3"><FormattedMessage id="OnlineSalesDetail.RefundAmount" /></Col>
                                    <Col>
                                        <FormattedMessage id="OnlineSalesDetail.RefundAmount" >
                                            {
                                                placeholder => <Input type="number" onChange={(e) => this.setState({ amount: e.target.value })} required placeholder={placeholder} min="0.01" maxLength={this.state.selectedVoucher && this.state.selectedVoucher.salePrice} step="0.01" />
                                            }
                                        </FormattedMessage>
                                </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-3"><FormattedMessage id="OnlineSalesDetail.Reason" /></Col>
                                    <Col> 
                                        <CustomSelect options={getStripeRefundReasons()} isClearable value={getStripeRefundReasons().find(el => el.value === this.state.reason)} name="reason" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OnlineSalesDetail.Reason" />} />
                                    </Col>
                                </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><FormattedMessage id="OnlineSalesDetail.Notes" /></Col>
                                <Col> {/* TODO : fraudulent or requested by customer*/}
                                    <FormattedMessage id="OnlineSalesDetail.Notes" >
                                        {
                                            placeholder => < Input type="textarea" onChange={(e) => this.setState({ notes: e.target.value })} required placeholder={placeholder} minLength="10" maxLength="200" />
                                        }
                                    </FormattedMessage>
                                </Col>
                                </Row>
                            </form>
                        </StyledCard>
                    </ModalBody>
                </Modal>

                {
                    registSaleOnPmsModal ?
                        <RegistSaleOnPmsModal isOpen={registSaleOnPmsModal} toggle={this.toggleRegistSaleOnPmsModal} vouchers={this.state.onlinePayment.vouchers} name={this.state.onlinePayment.name} email={this.state.onlinePayment.email} />
                        : 
                        <div/>
                }

                <BlockUi tag="div" blocking={this.state.block}>
                <Row className="mb-1">
                        <Col className="text-right d-flex justify-content-end">
                            
                            <Button className="btn-sm btn-host mr-1" id="viewReceiptTooltip" disabled={this.state.onlinePayment.receiptUrl == undefined} onClick={() => window.open(this.state.onlinePayment.receiptUrl, "_blank")} ><i className="fas fa-receipt" ></i> </Button>
                            <UncontrolledTooltip placement="bottom" target="viewReceiptTooltip">
                                <FormattedMessage id="OnlineSalesDetail.ViewReceipt" />
                            </UncontrolledTooltip>
                            <Authorization
                                perform="onlineSales:send"
                                yes={() => (
                                    <div>
                                        <Button className="btn-sm btn-host mr-1" id="emailTooltip" onClick={this.sendNotification.bind(this)}  ><i className="far fa-envelope"></i> </Button>
                                        <UncontrolledTooltip placement="bottom" target="emailTooltip">
                                            <FormattedMessage id="OnlineSalesDetail.SendVoucher" />
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                            {
                                onlinePayment.channelConfigId !== 2 ?
                                    <span>
                                        <Button className="btn-sm btn-host mr-1" id="paymentStatusTooltip" onClick={this.checkPaymentUpdates.bind(this)}><i class="far fa-check-square"></i></Button>
                                        <UncontrolledTooltip placement="bottom" target="paymentStatusTooltip">
                                            <FormattedMessage id="OnlineSalesDetail.SyncPaymentStatus" />
                                        </UncontrolledTooltip>
                                    </span>
                                    :
                                    <span/>
                            }
                            <Authorization
                                perform="onlineSales:registOnPms"
                                yes={() => (
                                    <div>
                                        <Button className="btn-sm btn-host" id="registOnPmsTooltip" onClick={this.toggleRegistSaleOnPmsModal} disabled={isRegisterOnPmsDisabled}>
                                            <i class="far fa-share-square" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="registOnPmsTooltip">
                                            <FormattedMessage id="OnlineSalesDetail.RegistOnPms" />
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                    </Col>
                    </Row>
                </BlockUi>

                <StyledCard icon={'fas fa-gift'} title={'OnlineSalesDetail.OnlineSale'} help={help} error={this.state.error} block={this.state.block}>
                    <Row className="mb-2">
                        <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.CreateDate" /></Col>
                        <Col className="col-4"> {this.state.onlinePayment.createdAt}</Col>
                    </Row>
                    {
                        onlinePayment.channelConfigId !== 2 ?
                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.SessionId" /></Col>
                                <Col>{this.state.onlinePayment.sessionId}</Col>
                                <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.PaymentIntentId" /></Col>
                                <Col>{this.state.onlinePayment.paymentIntentId}</Col>
                            </Row>
                            :
                            <div />
                    }
                    <Row className="mb-2">
                        <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.Name" /></Col>
                        <Col> {this.state.onlinePayment.name}</Col>
                        <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.Email" /> </Col>
                        <Col> {this.state.onlinePayment.email}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.PaymentStatus" /></Col>
                        <Col> <Badge className="p-2 btn-host" >{this.state.onlinePayment.paymentStatus}</Badge></Col>
                        <Col className="col-2"><FormattedMessage id="OnlineSalesDetail.SaleDate" /></Col>
                        <Col> {this.state.onlinePayment.createdAt}</Col>
                    </Row>
                    {onlinePayment.from && onlinePayment.to ?
                        <div>
                            <Row className="mt-4">
                                <Col>
                                    <h6><FormattedMessage id="VoucherOperationDetails.GiftInfo" /></h6>
                                </Col>
                            </Row>
                            <hr className="my-1 mb-2" />
                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="VoucherManagement.From" /></Col>
                                <Col> {onlinePayment.from}</Col>
                                <Col className="col-2"><FormattedMessage id="VoucherManagement.To" /></Col>
                                <Col> {onlinePayment.to}</Col>
                            </Row>

                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="VoucherManagement.Sender" /></Col>
                                <Col> {onlinePayment.sender}</Col>
                                <Col className="col-2"><FormattedMessage id="VoucherManagement.Recipient" /></Col>
                                <Col> {onlinePayment.recipient}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="VoucherManagement.Message" /></Col>
                                <Col> {onlinePayment.message}</Col>
                            </Row>
                        </div>
                        : ''}
                </StyledCard >
                <div className="mb-2" />
                <StyledCard icon={'fas fa-shopping-cart'} title={'OnlineSalesDetail.ShoppingCard'} help={help} block={this.state.block}>
                    <Row className="mb-2">
                        <Col><FormattedMessage id="OnlineSalesDetail.VoucherCode" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.Quantity" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.PayedAmount" /></Col>
                    </Row>
                {this.state.onlinePayment.items && this.state.onlinePayment.items.map((el, k) =>
                    <Row key={k} className="mb-2">
                        <Col>{el.voucherTypeCode}</Col>
                        <Col>{el.quantity}</Col>
                        <Col>{el.payedAmount}</Col>
                    </Row>
                    
                )}
                </StyledCard >
                <div className="mb-2"/>
                <StyledCard icon={'fas fa-ticket-alt'} title={'OnlineSalesDetail.EmitedVouchers'} help={help} block={this.state.block}>
                    <Row className="mb-2 ">
                        <Col className="col-3"><FormattedMessage id="OnlineSalesDetail.VoucherId" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.Status" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.ValidUntil" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.SequenceNumber" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.Code" /></Col>
                        {
                            onlinePayment.channelConfigId !== 2 ?
                                <Col>
                                    <FormattedMessage id="OnlineSalesDetail.Refund" />
                                </Col>
                                :
                                <span />
                        }
                        {/*<Col className="col-4">Description</Col>*/}
                    </Row>
                    {
                        this.state.onlinePayment.vouchers && this.state.onlinePayment.vouchers.map((el, k) =>
                            <Row key={k} className="mb-1">
                                <Col className="col-3">
                                    <a onClick={this.voucherDetail.bind(this, el.id)} style={{ cursor: "pointer", color: "blue" }}>{getRightVoucherId(el.voucherId, el.voucherIdFirst8)} </a>
                                </Col>
                                <Col>
                                    <Badge className="p-2 btn-host"> {el.status}</Badge>
                                </Col>
                                <Col>
                                    {el.validUntil}
                                </Col>
                                <Col>
                                    {el.ordinal}
                                </Col>
                                <Col>
                                    {el.voucherTypeCode}
                                </Col>
                                { onlinePayment.channelConfigId !== 2 ?
                                        <Col className="text-right">
                                            <Authorization
                                                perform="onlineSales:refund"
                                                yes={() => (
                                                    <div>
                                                        <Button className="btn-sm btn-host mb-1" disabled={el.status == 'refunded'} id={"RefundTooltip" + k} onClick={() => this.refund(this, el.id)}><i className="fas fa-undo"></i> </Button>
                                                        <UncontrolledTooltip placement="left" target={"RefundTooltip" + k}>
                                                            <FormattedMessage id="OnlineSalesDetail.RefundPayment" />
                                                        </UncontrolledTooltip>
                                                    </div>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                        :
                                        <span />
                                }
                            {/*<Col className="col-4">{el.voucherTypeDescription}</Col>*/}
                            </Row>
                        )}
                </StyledCard>
                <div className="mb-2" />
                <NotificationCard icon={'fas fa-at'} block={this.state.block} list={this.state.onlinePayment.notifications} />
              

                </>

        );
    }
}

import React, { Component } from 'react';
import { Row, Col, Button, Input, Label, CustomInput, Form } from 'reactstrap';
import { MultiLangTextWithTitle } from '../Base/Common/CommonUIComponents';
import { handleNotification } from '../Base/Common/Notification';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization from '../Base/Authorization';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../Base/API';
import moment from "moment";
import CustomSelect from '../Base/Common/CustomSelect';

class VoucherConfigStep1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blblockTagsock: false,
            voucherTags:[]
        }
    }

    componentDidMount() {
        this.getVoucherTags();
    }

    getVoucherTags = () => {
        this.setState({ blockTags: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTags: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let tagOptions = [
                    { label: this.props.intl.formatMessage({ id: "generic.active" }), options: [] },
                    { label: this.props.intl.formatMessage({ id: "generic.inactive" }), options: [] }
                ];

                data.response && data.response.forEach((tag) => {
                    const obj = { label: tag.code, value: tag.id };
                    
                    tagOptions[tag.active ? 0 : 1].options.push(obj);
                });

                this.setState({ voucherTags: tagOptions, blockTags: false });
            }
            else {
                this.setState({ blockTags: false });
            }
        }, `/api/Voucher/Voucher/v1/voucherTag`)
    }


    renderMonthElement = (action, { month, onYearSelect }) => {
        const years = [
            { value: moment().year(), isDisabled: false },
            { value: moment().year() + 1, isDisabled: false },
            { value: moment().year() + 2, isDisabled: false }
        ];

        if (!years.some(y => y === month.year())) {
            years.unshift({ value: month.year(), isDisabled: true })
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col className="pr-1">
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col className="pl-1">
                        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)} style={{ border: '1px solid #ced4da', borderRadius: '0.25rem' }}>
                            {years.map((year, key) =>
                                <option value={year.value} disabled={year.isDisabled} key={key}>
                                    {year.value}
                                </option>
                            )}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }
    
    render() {
        const { block, voucherConfig, taxCodes } = this.props;

        return (
            <BlankCard title='EditVoucher.VoucherConfig' block={block} >
                <Form onSubmit={this.props.saveVoucher}>
                    <Row >
                        <Col>
                            <h5><FormattedMessage id="EditVoucher.VoucherConfig" /></h5>
                        </Col>
                        <Col className="text-right">
                            <Authorization
                                perform="cardConfigWizard:saveConfig"
                                yes={() => (
                                    <Button className="btn-host btn-sm" type="submit">
                                        <i className="fas fa-save " />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>
                    <hr className="my-1 border-host" />

                    <div style={{ zIndex: '99', position: 'relative' }}>
                        <Row className="my-2">
                            <Col className="col-2">
                                <Label>
                                    <b><FormattedMessage id="EditVoucher.Code" /></b>
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.Code" >
                                    {
                                        placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" value={voucherConfig.code ? voucherConfig.code : ''} name="code" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <b><FormattedMessage id="EditVoucher.MaxQuantity" /></b>
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.MaxQuantity" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} required min="1" max="10000" value={voucherConfig.maxQuantity !== null ? voucherConfig.maxQuantity : ''} name="maxQuantity" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>

                        </Row>

                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.PayedAmount" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.PayedAmount" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} required min="0" max="10000" step="0.01" value={voucherConfig.payedAmount !== null ? voucherConfig.payedAmount : ''} name="payedAmount" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.CardAmount" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.CardAmount" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} min="1" max="10000" step="0.01" value={voucherConfig.cardAmount !== null ? voucherConfig.cardAmount : ''} name="cardAmount" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.SalesPeriod" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <DateRangePicker
                                    startDate={voucherConfig.saleValidFrom ? moment(voucherConfig.saleValidFrom, 'YYYY-MM-DDTHH:mm:ssZ') : null}
                                    startDateId="saleValidFrom"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(24, 'month')}
                                    endDate={voucherConfig.saleValidTo ? moment(voucherConfig.saleValidTo, 'YYYY-MM-DDTHH:mm:ssZ') : null}
                                    endDateId="saleValidTo"
                                    onDatesChange={({ startDate, endDate }) => this.props.handleDatesChange('voucherConfig', startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    required
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                    readOnly={true}
                                    renderMonthElement={props => this.renderMonthElement(this.props.action, props)}
                                />
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.ValidUntil" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.NumMonth" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} min="1" max="48" value={voucherConfig.vouchersValidUntil !== null ? voucherConfig.vouchersValidUntil : ''} name="vouchersValidUntil" onChange={this.props.updateForm.bind(this, 'voucherConfig')} required />
                                    }
                                </FormattedMessage>


                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.TypePrefix" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.TypePrefix" >
                                    {
                                        placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="10" value={voucherConfig.defaultPrefix !== null ? voucherConfig.defaultPrefix : ''} name="defaultPrefix" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.DisplayOrder" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.DisplayOrder" >
                                    {
                                        placeholder => <Input type="number" min="1" max="100" placeholder={placeholder} required minLength="1" maxLength="50" value={voucherConfig.order !== null ? voucherConfig.order : ''} name="order" onChange={this.props.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            {/*        <Col className="col-2">
                                    <Label><b><FormattedMessage id="EditVoucher.Active" /></b></Label>
                                </Col>
                                <Col className="col-4">
                                    
                                </Col>
                        */}
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.VouhcherTag" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect
                                    name='voucherTagId'
                                    options={this.state.voucherTags}
                                    value={voucherConfig.voucherTagId ? this.state.voucherTags?.flatMap(el => el.options).find(el => el.value === voucherConfig.voucherTagId) : ''}
                                    onChange={this.props.updateComboForm}
                                    placeholder={<FormattedMessage id="EditVoucher.VouhcherTag" />}
                                    isLoading={this.state.blockTags}
                                    isClearable
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row className="mb-2">
                        <Col className="col-2">

                        </Col>
                        <Col>
                            <MultiLangTextWithTitle
                                textList={voucherConfig.multiLingual}
                                updateTitle={this.props.udpateText.bind(this)}
                                udpateDescription={this.props.udpateText.bind(this)}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.IsMoneyVoucher" />} checked={voucherConfig.isPaymentMode} name="isPaymentMode" id="isPaymentMode" onChange={this.props.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.ParcialRedeem" />} checked={voucherConfig.allowPartialRedeem} name="allowPartialRedeem" id="allowPartialRedeem" onChange={this.props.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.IncludesTax" />} checked={voucherConfig.salesHasTax} name="salesHasTax" id="salesHasTax" onChange={this.props.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.Active" />} checked={voucherConfig.active} name="active" id="active" onChange={this.props.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                    </Row>

                    {voucherConfig.isPaymentMode === false ?
                        <div>
                            <Row>
                                <Col>
                                    <h5><FormattedMessage id="EditVoucher.ApplicableVAT" /></h5>
                                </Col>
                                <Col className="text-right">
                                    <Authorization
                                        perform="cardConfigWizard:saveConfig"
                                        yes={() => (
                                            <Button className="btn-host btn-sm" onClick={() => this.props.toggleModal('vatModal')}>
                                                <i className="fas fa-plus " />
                                            </Button>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Col>
                            </Row>
                            <hr className="my-1 border-host" />
                            <Row>
                                <Col>
                                    <b><FormattedMessage id="EditVoucher.TaxCode" /></b>
                                </Col>
                                <Col>
                                    <b><FormattedMessage id="EditVoucher.Amount" /></b>
                                </Col>
                                <Col className="col-1" />
                            </Row>

                            {voucherConfig.voucherTypeVat && voucherConfig.voucherTypeVat.map((vat, key) =>
                                <Row key={key} className="my-1">
                                    <Col>
                                        {taxCodes.some(tc => tc.value === vat.taxCode) ? taxCodes.find(tc => tc.value === vat.taxCode).label : vat.taxCode}
                                    </Col>
                                    <Col>
                                        {parseFloat(vat.amount).toFixed(2)}
                                    </Col>
                                    <Col className="col-1">
                                        <i className="fas fa-trash-alt" style={{ cursor: 'pointer' }} onClick={(e) => this.props.handleVoucherTypeVat(e, vat)} />
                                    </Col>
                                </Row>
                            )}
                        </div>
                        : ''}
                </Form>
            </BlankCard>
        );
    }
}

export default injectIntl(VoucherConfigStep1)
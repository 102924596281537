import React, { Component } from 'react';
import { Modal, Button, Progress, Row, Col, CardBody, Card, Badge } from 'reactstrap';
import { getAPI } from '../Base/API';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';
import { SelectHotel, BlankCard } from '../Base/Common/CommonUIComponents';
import { Pie, Line } from 'react-chartjs-2';
import moment from "moment";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export class RedeemDashboard extends Component {
    static displayName = RedeemDashboard.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            colorOnline: '#184C87',
            colorPms: '#00b4d8',
            colorCash: '#AAB71A',
            colorService: '#708843',
            colorDiscount: '#db231c'
            
        }
    }

    componentDidMount() {
        this.getRedeems();

    }

    getRedeems() {
        this.setState({ block: true });
        let param = '?fromDate=' + this.props.startDate.format('YYYY-MM-DD') + '&toDate=' + this.props.endDate.format('YYYY-MM-DD');

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                console.log(data)
                let totalRedeemCash = 0;
                let totalRedeemServices = 0;
                let cardSummaryCash = [];
                let cardSummaryService = [];
                let dailyRedeemsCash = [];
                let dailyRedeemsService = [];

                let revCategoryName = [];
                let revCategoryAmount = [];


                data.response.map((el, k) => {

                    if (el.isPay === false) {
                        totalRedeemServices += el.count;
                        let cardsum = cardSummaryService.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryService.push(el)
                        } else {
                            cardsum.salePrice += el.salePrice;
                            cardsum.count += el.count;
                        }

                        let auxService = dailyRedeemsService.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyRedeemsService.push({ x: moment(el.createdAt).toDate(), y: el.count });
                        } else {
                            dailyRedeemsService[auxService] = { x: dailyRedeemsService[auxService].x, y: el.count + dailyRedeemsService[auxService].y };
                        }

                        if (el.revCategory != null) {
                            let idx = revCategoryName.findIndex( x => x === el.revCategory);
                            if (idx < 0) {
                                revCategoryName.push(el.revCategory);
                                revCategoryAmount.push(el.count);
                            } else {
                                revCategoryAmount[idx] += el.count;
                            }

                        }
                        

                    } else {
                        totalRedeemCash += el.reddemsAmount;
                        
                        let cardsum = cardSummaryCash.find(item => item.code === el.code);
                        if (cardsum === undefined) {
                            cardSummaryCash.push(el)
                        } else {
                            cardsum.salePrice += el.salePrice;
                            cardsum.reddemsAmount += el.reddemsAmount;
                            cardsum.count += el.count;
                        }
                        let auxService = dailyRedeemsCash.findIndex(item => item.x.getTime() === moment(el.createdAt).toDate().getTime());
                        if (auxService < 0) {
                            dailyRedeemsCash.push({ x: moment(el.createdAt).toDate(), y: el.reddemsAmount });
                        } else {
                            dailyRedeemsCash[auxService] = { x: dailyRedeemsCash[auxService].x, y: el.reddemsAmount + dailyRedeemsCash[auxService].y };
                        }


                    }

                    


                })

                this.setState({
                    totalRedeemServices: totalRedeemServices,
                    totalRedeemCash: totalRedeemCash,
                    cardSummaryCash: cardSummaryCash.sort((a, b) => b.reddemsAmount - a.reddemsAmount),
                    cardSummaryService: cardSummaryService.sort((a, b) => b.count - a.count),
                    dailyRedeemsCash: dailyRedeemsCash.sort((a, b) => a.x - b.x),
                    dailyRedeemsService: dailyRedeemsService.sort((a, b) => a.x - b.x),
                    revCategoryAmount: revCategoryAmount,
                    revCategoryName: revCategoryName,
                    block: false 
                });
            }
        }, '/api/Voucher/Dashboard/v1/RedeemStatus' + param);
            }


    render() {
        let dataPieCard = {
            datasets: [{
                data: [this.state.totalCash, this.state.totalCard],
                backgroundColor: [
                    this.state.colorCash,
                    this.state.colorService
                ],
            }],
            labels: [
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Cash" })}`,
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Services" })}`
            ],

        };

        let dataPieChannel = {
            datasets: [{
                data: [this.state.totalOnline, this.state.totalPMS],
                backgroundColor: [
                    this.state.colorOnline,
                    this.state.colorPms
                ],
            }],
            labels: [
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Online" })}`,
                `€ ${this.props.intl.formatMessage({ id: "SalesDashboard.Pms" })}`
            ],

        };

        let dataPieRevCat = {
            datasets: [
                {
                    data: this.state.revCategoryAmount ? this.state.revCategoryAmount : [],
                    backgroundColor: [
                        this.state.colorCash,
                        this.state.colorService
                    ]
                }
            ],
            labels: this.state.revCategoryName ? this.state.revCategoryName: [],
        };

        let optionsPie = {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    // fontColor: 'rgb(255, 99, 132)'
                }
            }
        }

        let objSalesType = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Cash" }),
                        "data": this.state.dailyRedeemsCash ? this.state.dailyRedeemsCash : [],
                        "fill": false,
                        "borderColor": this.state.colorCash,
                        "backgroundColor": this.state.colorCash+'11',
                        "borderWidth": "2",
                        "lineTension": "0.2"
                    }


                ]
        };

        let objSalesChannel = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "SalesDashboard.Services" }),
                        "data": this.state.dailyRedeemsService ? this.state.dailyRedeemsService : [],
                        "fill": false,
                        "borderColor": this.state.colorService,
                        "backgroundColor": this.state.colorService + '22',
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                ]
        };

        let optionsAmount = {
            legend: {
                position: "left",
                display: false,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            quarter: 'MMM D'
                        }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {

                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }

        return (
            <Row>
                <Col className="col-4" >
                    <BlankCard icon={'fas fa-money-bill-alt'} title={'Sales '} error={this.state.error} block={this.state.block}>
                        <div style={{ paddingBottom: '5rem' }}> 
                        <h4 className="mb-4"><FormattedMessage id="RedeemDashboard.TopCashRedeems" /></h4>

                        {this.state.cardSummaryCash && this.state.cardSummaryCash.map((s, k) =>
                            <div key={k}>
                                <Row className="mb-2">
                                    <Col>
                                        <Row>
                                            <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                <b>{s.count} x {s.code}</b>

                                            </Col>
                                            <Col className="col-3 text-right">

                                                <FormattedNumber
                                                    value={s.reddemsAmount}
                                                    style="currency" currency={'EUR'} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Progress color={s.isPay ? 'cash' : 'service'} value={(s.reddemsAmount / this.state.totalRedeemCash) * 100}  ></Progress>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                                <hr className="my-1" />
                            </div>
                        )}
                        <h4 className="mb-4"><FormattedMessage id="RedeemDashboard.TopServiceRedeems" /></h4>
                        {this.state.cardSummaryService && this.state.cardSummaryService.map((s, k) =>
                            <div key={k}>
                                <Row className="mb-2">
                                    <Col>
                                        <Row>
                                            <Col style={{ color: s.isPay ? this.state.colorCash : this.state.colorService }}>
                                                <b>{s.count} x {s.code}</b>

                                            </Col>
                                            <Col className="col-3 text-right">

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Progress color={s.isPay ? 'cash' : 'service'} value={(s.count / this.state.totalRedeemServices) * 100}  ></Progress>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                                <hr className="my-1" />
                            </div>
                        )}

                        <div style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                            <Row className="mt-2">
                                <Col><h6><FormattedMessage id="RedeemDashboard.CardTypes" /></h6></Col>
                                <Col><h6></h6></Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Row>
                                        <Col>
                                            <Badge style={{ backgroundColor: this.state.colorCash }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Cash" /><br />
                                            <Badge style={{ backgroundColor: this.state.colorService }} className="px-2">&nbsp;</Badge> <FormattedMessage id="SalesDashboard.Services" />
                                            </Col>
                                        <Col>
                                            
                                            </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </div>
                        </div>




                    </BlankCard>
                </Col>
                <Col className="col-8">
                    <Row className="mb-2">
                        {/*<Col className="col-3">
                            <Card className="shadow" style={{ backgroundColor: '#f7f7f7', color: "#6c757d" }}>
                                <CardBody>
                                    <h6>Sold</h6>
                                    <div className="text-right font-weight-bold h5">
                                        <FormattedNumber
                                            value={this.state.totalRedeemCash}
                                            style="currency" currency={'EUR'} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        */}
                        <Col className=" col-6">
                            <Card className="shadow" style={{ backgroundColor: this.state.colorCash, color: "#ffffff" }}>
                                <CardBody>
                                    <h6><FormattedMessage id="SalesDashboard.Cash" /></h6>
                                    <div className="text-right font-weight-bold h5">
                                        <FormattedNumber value={this.state.totalRedeemCash} style="currency" currency={'EUR'} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-6">
                            <Card style={{ backgroundColor: this.state.colorService, color: "#ffffff" }}>
                                <CardBody>
                                    <h6><FormattedMessage id="SalesDashboard.Services" /></h6>
                                    <div className="text-right font-weight-bold h5">
                                        # {this.state.totalRedeemServices}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/*
                        <Col className="col-3">
                            <Card style={{ backgroundColor: this.state.colorDiscount, color: "#ffffff" }}>
                                <CardBody>
                                    <h6>Discount </h6>
                                    <div>
                                        <span className=" font-weight-bold h5"> {((this.state.totalDiscount / this.state.totalSale)).toFixed(2) * 100} %</span>
                                        <span className="float-right font-weight-bold h5">
                                            <FormattedNumber
                                                value={this.state.totalDiscount}
                                                style="currency" currency={'EUR'} />
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        */}
                    </Row>
                    <Row className="mb-2">
                        {/*
                        <Col className="col-3" >

                            <Row className="mb-2">
                                <Col >
                                    <BlankCard>
                                        <Pie height={210}
                                            data={dataPieRevCat} options={optionsPie}
                                        />
                                    </BlankCard>
                                </Col>

                            </Row>
                            <Row className="mb-2">
                                <Col >
                                    <BlankCard>
                                        <Pie height={210}
                                            data={dataPieChannel}
                                            options={optionsPie}
                                        />
                                    </BlankCard>
                                </Col>

                            </Row>
                            <Row className="mb-2">
                                <Col >
                                    <BlankCard>
                                        <Pie height={210}
                                            data={dataPieCnt}
                                            options={optionsPie}
                                        />
                                    </BlankCard>
                                </Col>

                            </Row>
                        </Col>
                        */}
                        <Col className="col-12" >
                            <Row className="mb-2">
                                <Col>
                                    <BlankCard>
                                        <Row>
                                            <Col className="col-12">
                                                <Line data={objSalesType} options={optionsAmount} height={80} />
                                            </Col>
                                        </Row>
                                    </BlankCard>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Row>
                                        <Col className="col-9">
                                            <BlankCard>
                                                <Line data={objSalesChannel} options={optionsAmount} height={120} />
                                            </BlankCard>
                                        </Col>
                                        <Col className="col-3" >
                                            <BlankCard>
                                                <Pie width="100%" data={dataPieRevCat} options={optionsPie} />
                                            </BlankCard>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default injectIntl(RedeemDashboard)
import React, { Component } from 'react';
import { Button, Card, CardBody, Row, Col, InputGroup } from 'reactstrap';
import { handleNotification } from "../Base/Common/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Base/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import { FormattedMessage } from 'react-intl';
import { getAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import TimelineElement from '../Base/Common/TimelineElement';
import Timeline from '../Base/Common/Timeline';
import '../Base/Common/TimelineElement.css';
import '../Base/Common/Timeline.css';
import { CommonHelper } from '../Base/Common/CommonUIComponents';

const DisplayIcon = ({ action, result }) => {
    var badgecolor = "badge-secondary";
    if (result === 2) badgecolor = "badge-danger"
    else if (result === 3) badgecolor = "badge-warning";

    var classname = "";
    if (action === 'Payment') classname = "fas fa-2x fa-credit-card"
    else if (action === 'PmsReservations') classname = "fas fa-2x fa-suitcase"
    else if (action === 'Invoice') classname = "fas fa-2x fa-receipt"
    else if (action === 'RateCode') classname = "fas fa-2x fa-dollar-sign"
    else if (action === 'OnlineSale') classname = "fas fa-2x fa-wallet"
    else if (action === 'User') classname = "fas fa-2x fa-user"
    else if (action === 'EmitVoucher') classname = "fas fa-2x fa-gift"
    else if (action === 'SetVoucherState') classname = "fas fa-2x fa-gift"
        

    return <span className={'badge ' + badgecolor} style={{ width: '28px' }}><i className={classname}></i></span>;
}

class LogsDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            detailBlock: false,
            actionSelected: null,
            typeSelected: null,
            statusSelected: false,
            resultsPerPage: 10,
            currentPage: 0,
            selectedEvent: {},
            totalEvents: 0,
            events: [],
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            lastSelected: 0
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    viewEventDetails = (id) => {
        this.setState({ detailBlock: true });

        const event = this.state.events.find(event => event.id === id);

        this.setState({ selectedEvent: event, lastSelected: id, detailBlock: false });
    }

    componentDidMount() {
        this.getEvents();
    }

    changePage = (evt, val) => {
        this.setState({
            currentPage: this.state.currentPage + val, block: true
        }, () => this.getEvents());
    }

    getEvents = () => {
        this.setState({ block: true });
        let params = '';
        params += this.state.actionSelected ? `&action=${this.state.actionSelected}` : '';
        params += this.state.typeSelected ? `&eventType=${this.state.typeSelected}` : '';
        params += this.state.statusSelected ? `&result=${this.state.statusSelected}` : '';

        getAPI((result) => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                this.setState({ events: data.response, totalEvents: data.count, block: false });
            }
        }, `/api/Voucher/v1/Events/channelEvents?&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}&pageIndex=${this.state.currentPage}&pageSize=10` + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    doSearch = () => {
        this.setState({
            currentPage: 0
        }, () => this.getEvents())
    }

    render() {
        const actionOptions = [
            {
                value: 'Checkout',
                label: <FormattedMessage id="LogsDashboard.Checkout" />
            },
            {
                value: 'Webhook',
                label: <FormattedMessage id="LogsDashboard.Webhook" />
            },
            {
                value: 'CheckStatus',
                label: <FormattedMessage id="LogsDashboard.CheckStatus" />
            },
            {
                value: 'Refund',
                label: <FormattedMessage id="LogsDashboard.Refund" />
            },
            {
                value: 'GetReservations',
                label: <FormattedMessage id="LogsDashboard.GetReservations" />
            },
            {
                value: 'ProcessReservation',
                label: <FormattedMessage id="LogsDashboard.ProcessReservation" />
            },
            {
                value: 'Register',
                label: <FormattedMessage id="LogsDashboard.Register" />
            },
            {
                value: 'Delete',
                label: <FormattedMessage id="LogsDashboard.Delete" />
            },
            {
                value: 'TestConnection',
                label: <FormattedMessage id="LogsDashboard.TestConnection" />
            }
        ]

        const typeOptions = [
            {
                value: 'Payment',
                label: <FormattedMessage id="LogsDashboard.Payment" />
            },
            {
                value: 'PmsReservations',
                label: <FormattedMessage id="LogsDashboard.PmsReservations" />
            },
            {
                value: 'Invoice',
                label: <FormattedMessage id="LogsDashboard.Invoice" />
            },
            {
                value: 'RateCode',
                label: <FormattedMessage id="LogsDashboard.RateCode" />
            },
            {
                value: 'OnlineSale',
                label: <FormattedMessage id="LogsDashboard.OnlineSale" />
            },
            {
                value: 'User',
                label: <FormattedMessage id="LogsDashboard.User" />
            },
            {
                value: 'EmitVoucher',
                label: <FormattedMessage id="LogsDashboard.EmitVoucher" />
            },
            {
                value: 'SetVoucherState',
                label: <FormattedMessage id="LogsDashboard.SetVoucherState" />
            }
        ]

        const statusOptions = [
            {
                value: '1',
                label: <FormattedMessage id="LogsDashboard.Ok" />
            },
            {
                value: '2',
                label: <FormattedMessage id="LogsDashboard.Error" />
            },
            {
                value: '3',
                label: <FormattedMessage id="LogsDashboard.Warning" />
            }
        ]

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Row>
                        <Col className="col-12 ">
                            <Card className="small bg-white border-top-0 border-right-0 border-left-0">
                                <CardBody>
                                    <Row>
                                        <Col className="col-2">
                                            <CustomSelect
                                                icon={'fa fa-exclamation-triangle fa-fw'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="LogsDashboard.Status" />}
                                                options={statusOptions}
                                                onChange={this.handleSelect.bind(this, 'statusSelected')}
                                            />
                                        </Col>
                                        <Col className="col-2">
                                            <CustomSelect
                                                icon={'fa fa-filter fa-fw'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="LogsDashboard.EventType" />}
                                                options={typeOptions}
                                                onChange={this.handleSelect.bind(this, 'typeSelected')}
                                            />
                                        </Col>
                                        <Col className="col-2">
                                            <CustomSelect
                                                icon={'fa fa-filter fa-fw'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="LogsDashboard.Action" />}
                                                options={actionOptions}
                                                onChange={this.handleSelect.bind(this, 'actionSelected')}
                                            />
                                        </Col>
                                        <Col className="col-2 px-1">
                                            <InputGroup size="sm logsDashboard">
                                                <DateRangePicker
                                                    startDate={this.state.startDate ? moment(this.state.startDate) : null}
                                                    startDateId="startDateId"
                                                    isOutsideRange={day => day > moment().add(1, 'days')}
                                                    endDate={this.state.endDate ? moment(this.state.endDate) : null}
                                                    endDateId="endDateId"
                                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                    small={true}
                                                    numberOfMonths={2}
                                                    showDefaultInputIcon={true}
                                                    required
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col className="text-right pr-0">
                                            <button className="btn btn-host shadow btn-sm" onClick={this.doSearch}>
                                                <span className="fas fa-search"></span>
                                            </button>

                                            <CommonHelper help={<FormattedMessage id="LogsDashboard.Help" />} id="logsDashboard" />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="small">
                        <Timeline layout='1-column' selectedEvent={this.state.selectedEvent} block={this.state.detailBlock} >
                            {this.state.events && this.state.events.map((item, key) =>
                                <div key={key} >
                                    <TimelineElement
                                        icon={<DisplayIcon action={item.eventType} result={item.result} />}
                                        date={moment(item.createdAt).calendar()}
                                        eventId={item.id}
                                        viewEventDetails={this.viewEventDetails}
                                        lastSelected={this.state.lastSelected}
                                        user={item.userName}
                                    >

                                        <div className="timeline-element-subtitle d-flex">
                                            {item.eventType === 'Payment' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'PmsReservations' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'Invoice' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'RateCode' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'OnlineSale' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'User' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'EmitVoucher' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.eventType === 'SetVoucherState' ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} /> </div> : ''}
                                            {item.ids && item.ids.length > 0 ? <div className="ml-1"> ( {item.ids.map(el => el).join(',')} )</div> : ''}
                                        </div>
                                    </TimelineElement>
                                </div>
                            )}
                        </Timeline>
                        <Row>
                            <Col className="col-5 text-center">
                                <Button className="btn-host shadow btn-sm float-left" disabled={this.state.currentPage === 0 ? true : false} onClick={() => this.changePage(this, -1)}><i className="fas fa-angle-left"></i></Button>
                                <span className="align-middle">
                                    <strong>
                                        <FormattedMessage id="LogsDashboard.Page" /> {this.state.currentPage + 1}  / {Math.ceil(this.state.totalEvents / this.state.resultsPerPage)}
                                    </strong>
                                </span>
                                <Button className="btn-host btn-sm shadow float-right" disabled={this.state.events && this.state.events.length < this.state.resultsPerPage ? true : false} onClick={() => this.changePage(this, 1)}><i className="fas fa-angle-right"></i></Button>

                            </Col>
                        </Row>
                    </div>
                </BlockUi>
            </div>

        );

    }
}
export default LogsDashboard;
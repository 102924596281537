import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from '../Base/Common/Notification';
import { postAPI } from '../Base/API';

export class RefoundModal extends Component {

    constructor(props) {
        super(props);
        this.saveRedeem = this.saveRedeem.bind(this);
        this.form = React.createRef();
        this.state = {
            block: false,
            redeem: {
                notes: null,
                quantity: 0,
                amount: 0
            },
            maxAmount: 0,
            maxQuantity: 0
        }
    }

    componentDidMount() {
        const { redeem } = this.state;
        redeem.rebateId = this.props.redeem.rebateId;
        redeem.usageHotel = this.props.redeem.usageHotel;
        redeem.businessDate = moment().format("YYYY-MM-DD");
        this.setState({
            redeem,
            minAmount: this.props.redeem.amount * -1,
            maxAmount: this.props.redeem.amount,
            minQuantity: this.props.maxQuantity ? this.props.maxQuantity * -1 : this.props.redeem.quantity * -1,
            maxQuantity: this.props.maxQuantity ? this.props.maxQuantity : this.props.redeem.quantity
        });
    }

    updateForm(evt) {
        if (evt && evt.target) {
            const { redeem } = this.state;
            const { name, value } = evt.target;
            redeem[name] = value;
            this.setState({ redeem });
        }
    }

    saveRedeem(e) {
        const { voucher } = this.props;
        const { redeem, minAmount, maxAmount, minQuantity , maxQuantity } = this.state;
        e.preventDefault();

        const req = {
            request: [redeem]
        };
        req.request[0].id = null;
        req.request[0].voucherId = voucher.id;

        if (voucher.voucherType.isPaymentMode) {
            const amount = document.getElementById('amount');
            if (redeem.amount < minAmount || redeem.amount > maxAmount) {
                amount.setCustomValidity(this.props.intl.formatMessage({ id: "AddRedeem.AmountOutOfBounds" }));
            }
            else {
                amount.setCustomValidity("");
            }
            if (req.request[0].amount > 0) {
                req.request[0].amount *= -1;
            }
        }
        else {
            const quantity = document.getElementById('quantity');
            if (redeem.quantity < minQuantity || redeem.quantity > maxQuantity) {
                quantity.setCustomValidity(this.props.intl.formatMessage({ id: "AddRedeem.QuantityOutOfBounds" }));
            }
            else {
                quantity.setCustomValidity("");
            }
            if (req.request[0].quantity > 0) {
                req.request[0].quantity *= -1;
            }
        }

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="AddRedeem.RedeemCreatedSuccessfully" />, <FormattedMessage id="AddRedeem.Success" />);
                        const serviceRefund = voucher.voucherType.isPaymentMode === false ? true : false;

                        this.setState({ block: false }, () => {
                            this.props.refresh();
                            this.props.toggle();
                        });
                    }
                }
            }, '/api/Voucher/Redeem/Redeem/v1', req);
        }
    }

    getRebates() {
        let arr = [];
        this.props.voucher && this.props.voucher.voucherType.voucherRebates && this.props.voucher.voucherType.voucherRebates.map((el, k) => arr.push({ value: el.id, label: el.code }))
        return arr;
    }

    render() {
        const { isOpen, toggle, voucher } = this.props;
        const { block, error, redeem, minAmount, maxAmount, minQuantity , maxQuantity } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
                <ModalBody className="modal-xl p-0">
                    <StyledCard title="AddRedeem.IssueRefound" block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.saveRedeem}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            {
                                voucher && voucher.voucherType.isPaymentMode === true ?
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <Label>
                                                <FormattedMessage id="AddRedeem.Amount" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <FormattedMessage id="AddRedeem.Amount" >
                                                {
                                                    placeholder => <Input type="number" min={minAmount} step="0.01" max={maxAmount} required placeholder={placeholder} id="amount" name="amount" value={redeem.amount} onChange={this.updateForm.bind(this)} required />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <Label>
                                                <FormattedMessage id="AddRedeem.Rebates" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <CustomSelect placeholder={<FormattedMessage id="AddRedeem.Rebates" />} required name="rebateId" options={this.getRebates()} value={this.getRebates().find(el => el.value === redeem.rebateId)} isDisabled />
                                        </Col>
                                        <Col className="col-2">
                                            <Label>
                                                <FormattedMessage id="AddRedeem.Quantity" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <FormattedMessage id="AddRedeem.Quantity">
                                                {
                                                    placeholder => <Input type="number" min={minQuantity} max={maxQuantity} required placeholder={placeholder} id="quantity" name="quantity" value={redeem.quantity} onChange={this.updateForm.bind(this)} required />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                            }
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddRedeem.Notes" />
                                    </Label>
                                </Col>
                                <Col className="col-10">
                                    <FormattedMessage id="AddRedeem.Notes">
                                        {
                                            placeholder => <Input type="textarea" placeholder={placeholder} minLength="10" maxLength="200" name="notes" value={redeem.notes || ''} onChange={this.updateForm.bind(this)} required />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </form>
                    </StyledCard>
                </ModalBody>
            </Modal>    
        );
    }
}
export default injectIntl(RefoundModal);
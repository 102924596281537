import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { postAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from "../Base/Common/Notification";
import { FormattedMessage } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';


export class AddRedeem extends Component {
    static displayName = AddRedeem.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.getHotels = this.getHotels.bind(this);
        this.getRebates = this.getRebates.bind(this);
        this.form = React.createRef();
        this.state = {
            block: false,
            modal: false,
            voucherRedeem: {}
        }
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateForm(f, evt) {
        let name = evt.target.name;
        let value = evt.target.value;
        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    saveRedeem(e) {
        e.preventDefault();

        let req = this.state.voucherRedeem;
        req.id = null;
        req.voucherId = this.props.voucher.id

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="AddRedeem.RedeemCreatedSuccessfully" />, <FormattedMessage id="AddRedeem.Success" />);
                        this.setState({ block: false }, () => this.props.refresh());
                    }
                }
                else this.setState({ block: false });
            }, '/api/Voucher/Redeem/Redeem/v1', { "request": [ this.state.voucherRedeem ] });
        }
    }

    getHotels() {
        let arr = [];
        this.props.voucher && this.props.voucher.voucherType.validHotels && this.props.voucher.voucherType.validHotels.map((el, k) => arr.push({ value: el.guid, label: el.name }))  
        
        return arr;
    }

    getRebates() {
        let arr = [];
        this.props.voucher && this.props.voucher.voucherType.voucherRebates && this.props.voucher.voucherType.voucherRebates.map((el, k) => arr.push({ value: el.id, label: el.code }))
        return arr;
    }

    updateFilterCombo(val, evt) {
      
        if (evt) {
            let name = evt.name;
            let value = val.value;

            this.setState(prevState => ({
                ...prevState,
                voucherRedeem: {
                    ...prevState.voucherRedeem,
                    [name]: value

                },
            }));

        }
    }

    render() {
        return (
            <StyledCard title='AddRedeem.AddRedeem' block={this.state.block} error={this.state.error}>
                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={this.saveRedeem.bind(this)}> <i className="fas fa-save " /></Button>
                    </Col>
                </Row>
                <form ref={this.form}>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="AddRedeem.Hotel" /></Label>
                        </Col>
                        <Col className="col-4">
                            <CustomSelect placeholder={<FormattedMessage id="AddRedeem.Hotel" />} required name="usageHotel" options={this.getHotels()} value={this.getHotels().find(el => el.value === this.state.voucherRedeem.hotelId)} onChange={this.updateFilterCombo.bind(this)} />
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="AddRedeem.Date" /></Label>
                        </Col>
                        <Col className="col-4">
                            <SingleDatePicker
                                id="businessDate"
                                isOutsideRange={day => day >= moment()}
                                date={this.state.voucherRedeem && this.state.voucherRedeem.businessDate ? moment(this.state.voucherRedeem.businessDate) : null}
                                onDateChange={date => this.setState(prevState => ({ ...prevState, voucherRedeem: { ...prevState.voucherRedeem, businessDate: moment(date).format("YYYY-MM-DD") } }))}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                small={true}
                                numberOfMonths={1}
                                required={true}
                                showDefaultInputIcon={true}
                            />
                        </Col>

                    </Row>

                    {this.props.voucher.voucherType.isPaymentMode === true ? 
                        <Row className="mb-2">

                            <Col className="col-2">
                                <Label><FormattedMessage id="AddRedeem.Amount" /></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddRedeem.Amount" >
                                    {
                                        placeholder => <Input type="number" min="-10000" step="0.01" max="10000" required placeholder={placeholder} name="amount" value={this.state.voucherRedeem.amount} onChange={this.updateForm.bind(this, 'voucherRedeem')} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                        :
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><FormattedMessage id="AddRedeem.Rebates" /></Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect placeholder={<FormattedMessage id="AddRedeem.Rebates" />} required name="rebateId" options={this.getRebates()} value={this.getRebates().find(el => el.value === this.state.voucherRedeem.rebateId)} onChange={this.updateFilterCombo.bind(this)} />
                            </Col>

                            <Col className="col-2">
                                <Label><FormattedMessage id="AddRedeem.Quantity" /></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddRedeem.Quantity">
                                    {
                                        placeholder => <Input type="number" min="1" max="10000" required placeholder={placeholder} name="quantity" value={this.state.voucherRedeem.quantity} onChange={this.updateForm.bind(this, 'voucherRedeem')} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                    }

                    
                    <Row className="mb-2">
                        <Col className="col-2">
                        <Label><FormattedMessage id="AddRedeem.Invoice" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="AddRedeem.Invoice" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} minLength="1" maxLength="50" name="targetReference" value={this.state.voucherRedeem.invoiceReference} onChange={this.updateForm.bind(this, 'voucherRedeem')} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col>
                          
                        </Col>
                           
                        </Row>
                        
                        <Row className="mb-2">
                            <Col className="col-2">
                            <Label><FormattedMessage id="AddRedeem.Notes" /></Label>
                            </Col>
                            <Col className="col-10">
                                <FormattedMessage id="AddRedeem.Notes">
                                    {
                                    placeholder => <Input type="textarea" placeholder={placeholder} minLength="10" maxLength="200" name="notes" value={this.state.voucherRedeem.notes} onChange={this.updateForm.bind(this, 'voucherRedeem')} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </form>

            </StyledCard>
        );
    }
}
